import { Dialog, Transition } from '@headlessui/react'
import React, {Fragment} from 'react'
import {XMarkIcon} from "@heroicons/react/24/outline";


const VideoPopup = ({isOpen,setIsOpen, videoLink}) => {



    return (
        <Layout
            title={''}
            setIsOpen={async (r) => {
                setIsOpen(r);
            }}
            isOpen={isOpen}
        >

            <iframe
                src={videoLink}
                title='videoLink'
                frameBorder="0" webkitallowfullscreen mozallowfullscreen allowFullScreen
                className=" w-full h-[50vh]"
            />

        </Layout>

    )
}


const Layout = ({title, isOpen, setIsOpen, children}) => {


    return (
        <Transition appear show={isOpen} as={Fragment}>
            <Dialog
                as="div"
                className="fixed inset-0 z-50 overflow-y-auto"
                onClose={setIsOpen}
            >
                <div className="min-h-screen px-4 text-center">
                    <Transition.Child
                        as={Fragment}
                        enter="ease-out duration-300"
                        enterFrom="opacity-0"
                        enterTo="opacity-100"
                        leave="ease-in duration-200"
                        leaveFrom="opacity-100"
                        leaveTo="opacity-0"
                    >
                        <Dialog.Overlay className="fixed inset-0 bg-gray-900 bg-opacity-90 z-5 transition-opacity" />
                    </Transition.Child>

                    {/* This element is to trick the browser into centering the modal contents. */}
                    <span
                        className="inline-block h-screen align-middle"
                        aria-hidden="true"
                    >&#8203;</span>
                    <Transition.Child
                        as={Fragment}
                        enter="ease-out duration-300"
                        enterFrom="opacity-0 scale-95"
                        enterTo="opacity-100 scale-100"
                        leave="ease-in duration-200"
                        leaveFrom="opacity-100 scale-100"
                        leaveTo="opacity-0 scale-95"
                    >
                        <div className="inline-block w-full max-w-4xl my-8 text-left align-middle transition-all transform ">

                            <div className="flex justify-between    items-center">
                                <div>
                                    <h3 className="text-lg font-medium leading-6 text-white">
                                        {title}
                                    </h3>
                                </div>
                                <div>
                                    <button className=" border-0" onClick={() => {setIsOpen(false)}}><XMarkIcon className="w-6 h-6 p-1 fill-current rounded-full   text-white" /></button>
                                </div>
                            </div>

                        <div className={'max-w-7xl'}>

                            {children}

                        </div>


                        </div>
                    </Transition.Child>
                </div>
            </Dialog>
        </Transition>
    )

}

export default VideoPopup;
