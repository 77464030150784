import {useEffect, useState} from "react";
import {useNavigate, useParams} from "react-router-dom";
import Layout from "../../components/layout/layout";
import Loading from "../../components/common/loading";
import {useForm } from "react-hook-form";
import InputText from "../../components/form/input-text";
import {useTranslation} from "react-i18next";
import InputBaseSelect from "../../components/form/input-base-select";
import InputSubmit from "../../components/form/input-submit";
import axios from 'axios';
import {Button} from "../../components/common/button";
import {CalendarIcon, PlayCircleIcon} from "@heroicons/react/24/outline";
import {CALL_LINK, HOW_IT_WORK} from "../../constants/urls";
import {emailRegex} from "../../constants/regex";


function TryDokless({siteConfig, storyBlok, language}) {

    const [pageDetail, setPageDetail] = useState(false);
    const [isLoadingPage, setLoadingPage] = useState(true);
    const [isSendLoading, setSendLoading] = useState(false);
    const { t } = useTranslation();
    const [exception, setException] = useState(false);
    const [success, setSuccess] = useState(false);


    const {
        register,
        handleSubmit,
        formState: { errors }
    } = useForm();

    let navigate = useNavigate();

    let {page}  = useParams();
    const { i18n } = useTranslation();


    useEffect(() => {
        setLoadingPage(true);
        storyBlok.get(`cdn/stories/pages/form/try-dokless`, {"language": language})
            .then(response => {
                setPageDetail(response.data.story.content.body);
                setLoadingPage(false);
            }).catch(error => {
            navigate("/")
        });

    }, [page, storyBlok, navigate, language]);


    if(isLoadingPage){
        return (<><Loading /></>);
    }

    const pageData = (pageDetail ?? [])?.find(r => r.component === 'page') ?? false


    const onSubmit = async (data) => {
        data['locale'] = i18n.language.includes('en') ? 'en' : 'it';
        setSuccess(false);
        setException(false);
        setSendLoading(true);
        axios.post('https://api.dokless.com/api/request_demo', data)
            .then(function (response) {
                setSuccess(true);
            }).catch(function (error) {
            setException(true);
        }).finally(function () {
                setSendLoading(false);
        });

    };

    return (
        <Layout siteConfig={siteConfig} isDark={false} isFixed={true}>




            <div className="h-auto lg:h-screen relative isolate bg-white">
                <div className="mx-auto grid max-w-7xl grid-cols-1 lg:grid-cols-2">
                    <div className="relative px-6 pb-20 pt-24 sm:pt-32 lg:static lg:px-8 lg:py-36">
                        <div className="mx-auto max-w-xl lg:mx-0 lg:max-w-lg">
                            <h2 className="text-3xl font-bold tracking-tight text-indigo-950">{pageData.title ?? ''}</h2>
                            <p className="mt-6 text-lg leading-1 tracking-tight text-indigo-950/75"  dangerouslySetInnerHTML={{__html:  storyBlok.richTextResolver.render(pageData.description ?? [])}} />
                            <div className={`mt-8  md:flex   md:justify-start md:items-center gap-x-2`}>
                                <Button color={'indigoDark'} href={CALL_LINK} target={'_blank'} className={'w-full md:w-auto'}>
                                    <CalendarIcon  className={'h-5 w-5'} />
                                    <span className="ml-2.5">{t("app.common.book")}</span>
                                </Button>


                                <Button variant={'outline'}  href={HOW_IT_WORK} color={'indigoDark'} className={'mt-4 w-full md:w-auto md:mt-0'}>
                                    <PlayCircleIcon  className={'h-6 w-6'} />
                                    <span className="ml-2.5">{t("app.watch_video")}</span>
                                </Button>

                            </div>
                        </div>
                    </div>
                    <form onSubmit={handleSubmit(onSubmit)} className="px-6 pb-24  sm:pb-32 lg:px-8 lg:py-36">
                        <div className="mx-auto max-w-xl lg:mr-0 lg:max-w-lg">
                            <div className="grid grid-cols-1 gap-x-8 gap-y-6 sm:grid-cols-2">

                                <InputText
                                    id={"first_name"}
                                    type={"text"}
                                    errors={errors.first_name}
                                    input={{ ...register("first_name", { required: true }) }}
                                    label={t("app.form.firstname")}
                                />

                                <InputText
                                    id={"last_name"}
                                    type={"text"}
                                    errors={errors.last_name}
                                    input={{ ...register("last_name", { required: true }) }}
                                    label={t("app.form.lastname")}
                                />

                                <div className={'col-span-2'}>
                                    <InputText
                                        id={"email"}
                                        type={"text"}
                                        errors={errors.email}
                                        input={{ ...register("email", { required: true, pattern: emailRegex }) }}
                                        label={t("app.form.company_mail")}
                                    />

                                </div>

                                <InputText
                                    id={"company_name"}
                                    type={"text"}
                                    errors={errors.company_name}
                                    input={{ ...register("company_name", { required: true }) }}
                                    label={t("app.form.company_name")}
                                />

                                <InputText
                                    id={"phone_number"}
                                    type={"text"}
                                    errors={errors.phone_number}
                                    input={{ ...register("phone_number", { required: true }) }}
                                    label={t("app.form.phone")}
                                />


                                <div className={'col-span-2'}>
                                    <InputBaseSelect
                                        id={'source'}
                                        options={[{name: "LinkedIn", value: "LinkedIn"},{name: "Google", value: "Google"},{name: "Instagram", value: "Instagram"},{name: "Email", value: "Email"},{name: t("app.form.friend"), value: 'Friend'},{name: t("app.form.other"), value: 'other'}]}
                                        errors={errors.source}
                                        input={{...register("source", { required: true })}}
                                        label={t("app.form.hear_where")}
                                    />


                                </div>

                            </div>



                            {
                                exception && (
                                    <div className="mt-5">
                                        <div className={`bg-red-50 border-l-4 border-red-400 p-4`}>
                                            <div className="flex">
                                                <div className="ml-3">
                                                    <p className={`text-sm text-red-700`}>
                                                        {t("app.form.error")}
                                                    </p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                )
                            }


                            {
                                success && (
                                    <div className={'mt-5 mb-5'}>
                                        <div className={`bg-green-50 border-l-4 border-green-400 p-4`}>
                                            <div className="flex">
                                                <div className="ml-3">
                                                    <p className={`text-sm text-green-700`}>
                                                        {t("app.form.success")}
                                                    </p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                )
                            }


                            <div className="mt-8 flex justify-end">



                                <InputSubmit
                                    isLoading={isSendLoading}
                                    isFullWith={true}
                                    label={t("app.form.btn_confirm")}
                                />
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </Layout>
    );
}






export default TryDokless;
