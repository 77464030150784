import {useEffect, useState} from "react";
import {useNavigate, useParams} from "react-router-dom";
import Layout from "../../components/layout/layout";
import { motion } from "framer-motion";
import Loading from "../../components/common/loading";
import Testimonials from "../../components/common/testimonials";
import SectionWorld from "../../components/common/section-world";
import {Button} from "../../components/common/button";
import {PlayCircleIcon} from "@heroicons/react/24/outline";
import {TypingText} from "../../components/animations/world/custom-texts";
import {useTranslation} from "react-i18next";
import {Link} from "react-router-dom";
import {HOW_IT_WORK, TRY_DOKLESS} from "../../constants/urls";






function PlatformPage({siteConfig, storyBlok, language}) {

    const [pageDetail, setPageDetail] = useState(false);
    const [homeData, setHomeData] = useState(false);

    const [isLoadingPage, setLoadingPage] = useState(true);
    const [isLoadingHome, setLoadingHome] = useState(true);

    let navigate = useNavigate();

    let {page}  = useParams();
    const { t } = useTranslation();

    const staggerContainer = (staggerChildren, delayChildren) => ({
        hidden: {},
        show: {
            transition: {
                staggerChildren,
                delayChildren,
            },
        },
    });

    const textVariant2 = {
        hidden: {
            opacity: 0,
            y: 20,
        },
        show: {
            opacity: 1,
            y: 0,
            transition: {
                type: 'tween',
                ease: 'easeIn',
            },
        },
    };


    useEffect(() => {
        setLoadingPage(true); // Assuming this sets a loading state
        storyBlok.get("cdn/stories/pages/platform/"+page, {"language": language})
            .then(response => {
                setPageDetail(response.data.story.content.body);
                setLoadingPage(false);
                window.scrollTo(0, 0)
            }).catch(error => {
                navigate("/")
        });

    }, [page, storyBlok, navigate, language]);

    useEffect(() => {
        storyBlok.get(`cdn/stories/homepage`, {"language": language})
            .then(response => {
                setHomeData(response.data.story.content.body);
                setLoadingHome(false);
            }).catch(error => {
            console.error('Error fetching story:', error);
        });
    }, [page, storyBlok, navigate, language]);

    if(isLoadingHome || isLoadingPage){
        return (<><Loading /></>);
    }

    const header = (pageDetail ?? [])?.find(r => r.component === 'platform_feature') ?? false
    const faqs = (pageDetail ?? [])?.find(r => r.component === 'faq_section') ?? false

  return (
      <Layout siteConfig={siteConfig}>


          <div className="bg-black bg-gradient-to-b from-indigo-950/90 pb-24 pt-52  -mt-20 ">
              <div className="mx-auto max-w-7xl px-6 lg:px-8">
                  <motion.div
                      variants={staggerContainer}
                      initial="hidden"
                      whileInView="show"
                      viewport={{ once: false, amount: 0.25 }}
                      className=" sm:text-center">


                      <TypingText title={header.subtitle} textStyles="font-display text-sm  text-white/75 lowercase capitalize" />

                      <motion.p
                          variants={textVariant2}
                          initial="hidden"
                          whileInView="show"

                          className="mt-2 text-5xl font-bold tracking-tight text-white sm:text-4xl lowercase capitalize">{header.title}
                      </motion.p>
                      <motion.p
                          variants={textVariant2}
                          initial="hidden"
                          whileInView="show"
                          className="mt-6 text-base text-white mx-auto max-w-3xl">
                          {header.description}
                      </motion.p>

                      <motion.h2
                          variants={textVariant2}
                          initial="hidden"
                          whileInView="show"
                          className={`mt-8  md:flex   md:justify-center md:items-center gap-x-2`}
                      >
                          <Button   href={TRY_DOKLESS} className={'w-full md:w-auto'}>
                              <span >{t("menu.try_dokless")}</span>
                          </Button>


                          <Button variant={'outline'}  href={HOW_IT_WORK} color={'white'} className={'mt-4 w-full md:w-auto md:mt-0'}>
                              <PlayCircleIcon  className={'h-6 w-6'} />
                              <span className="ml-2.5">{t("app.watch_video")}</span>
                          </Button>

                      </motion.h2>
                  </motion.div>
              </div>
              <div className="relative overflow-hidden pt-16">
                  <div className="mx-auto max-w-7xl px-6 lg:px-8">
                      <video playsinline="" autoplay="autoplay" loop="true" muted="muted" name="media" className={'mb-0 xl:mb-[-5%] rounded-md xl:rounded-xl shadow-2xl'}>
                          <source src={header.video_link} type="video/mp4"/>
                      </video>
                      <div className="relative hidden xl:flex" aria-hidden="true">
                          <div className="absolute -inset-x-20 bottom-0 bg-gradient-to-t from-black pt-[7%]" />
                      </div>
                  </div>
              </div>

          </div>


          {
              (header?.features ?? []).map(feature => (
                  feature.type === 'white' ? (
                      <div className="overflow-hidden bg-white py-24 sm:py-32">
                          <div className="mx-auto max-w-7xl px-6 lg:px-8">
                              <motion.div
                                  variants={staggerContainer}
                                  initial="hidden"
                                  whileInView="show"
                                  viewport={{ once: false, amount: 0.25 }}
                                  className="mx-auto grid max-w-2xl grid-cols-1 gap-x-8 gap-y-16 sm:gap-y-20 lg:mx-0 lg:max-w-none lg:grid-cols-2 items-center">


                                  <div className="lg:pr-8 lg:pt-4">
                                      <div className="lg:max-w-lg">
                                          <motion.p
                                               variants={textVariant2}
                                                initial="hidden"
                                                whileInView="show"
                                              className="mt-2 text-3xl font-bold tracking-tight text-indigo-950 sm:text-4xl">{feature.title}</motion.p>
                                          <motion.p
                                               variants={textVariant2}
                                                initial="hidden"
                                                whileInView="show"
                                              className="mt-6 text-lg leading-8 text-indigo-950/75">{feature.description}</motion.p>
                                      </div>
                                  </div>
                                  <motion.video
                                      variants={textVariant2}
                                      initial="hidden"
                                      whileInView="show"
                                      playsinline="" autoplay="autoplay" loop="true" muted="muted" name="media" className={'mb-[-5%] rounded-xl shadow-2xl'}>
                                      <source src={feature.video_link} type="video/mp4"/>
                                  </motion.video>

                              </motion.div>
                          </div>
                      </div>
                  ) : (
                      <div className="overflow-hidden bg-slate-900 py-24 sm:py-32">
                          <div className="mx-auto max-w-7xl px-6 lg:px-8">
                              <motion.div
                                  variants={staggerContainer}
                                  initial="hidden"
                                  whileInView="show"
                                  viewport={{ once: false, amount: 0.25 }}
                                  className="mx-auto grid max-w-2xl grid-cols-1 gap-x-8 gap-y-16 sm:gap-y-20 lg:mx-0 lg:max-w-none lg:grid-cols-2 items-center">
                                  <div className="lg:ml-auto lg:pl-4 lg:pt-4">
                                      <div className="lg:max-w-lg">
                                          <motion.p
                                              variants={textVariant2}
                                              initial="hidden"
                                              whileInView="show"
                                              className="mt-2 text-3xl font-bold tracking-tight text-white sm:text-4xl">{feature.title}</motion.p>
                                          <motion.p
                                              variants={textVariant2}
                                              initial="hidden"
                                              whileInView="show"
                                              className="mt-6 text-lg leading-8 text-white/75">{feature.description}</motion.p>

                                      </div>
                                  </div>
                                  <div className="flex items-start justify-end lg:order-first">
                                      <motion.video
                                          variants={textVariant2}
                                          initial="hidden"
                                          whileInView="show"
                                          playsinline="" autoplay="autoplay" loop="true" muted="muted" name="media" className={'mb-[-5%] rounded-xl shadow-2xl'}>
                                          <source src={feature.video_link} type="video/mp4"/>
                                      </motion.video>
                                  </div>
                              </motion.div>
                          </div>
                      </div>
                  )
              ))
          }



          <Testimonials componentDetail={(homeData ?? [])?.find(r => r.component === 'testimonial') ?? false} className={'py-32 bg-slate-900 '}/>


          <div className="bg-white">
              <div className="mx-auto max-w-7xl px-6 py-24 sm:pt-32 lg:px-8 lg:py-40">
                  <motion.div
                      variants={staggerContainer}
                      initial="hidden"
                      whileInView="show"
                      viewport={{ once: false, amount: 0.25 }}
                      className="lg:grid lg:grid-cols-12 lg:gap-8">
                      <div className="lg:col-span-5">
                          <motion.h2
                              variants={textVariant2}
                              initial="hidden"
                              whileInView="show"
                              className="text-2xl font-bold leading-10 tracking-tight text-indigo-950">{t("app.faq_title")}</motion.h2>
                          <motion.p
                              variants={textVariant2}
                              initial="hidden"
                              whileInView="show"
                              className="mt-4 text-base leading-8 text-indigo-950/75">{t("app.faq_description")}
                              <Link to="mailto:support@dokles.com" className="font-semibold text-indigo-800 hover:text-indigo-950">
                                  {t("app.faq_action")}
                              </Link>
                          </motion.p>
                      </div>
                      <div className="mt-10 lg:col-span-7 lg:mt-0">
                          <dl className="space-y-10">
                              {faqs.faqs.map((faq) => (
                                  <motion.div
                                      variants={textVariant2}
                                      initial="hidden"
                                      whileInView="show"
                                      key={faq._uid}>
                                      <dt className="text-base font-semibold leading-10 tracking-tight text-indigo-950">{faq.question}</dt>
                                      <dd className="mt-2 text-base leading-8 tracking-tight text-indigo-950/75">{faq.answer}</dd>
                                  </motion.div>
                              ))}
                          </dl>
                      </div>
                  </motion.div>
              </div>
          </div>




          <SectionWorld componentDetail={(homeData ?? [])?.find(r => r.component === 'world') ?? false} />



      </Layout>
  );
}






export default PlatformPage;
