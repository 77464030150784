import { motion, AnimatePresence } from "framer-motion"
import React, { useEffect, useState } from "react"

function cn(...classes) {
    return classes.filter(Boolean).join(" ")
}




export const ImagesSlider = ({
                                 images,
                                 upImages = false,
                                 children,
                                 overlay = true,
                                 overlayClassName = '',
                                 className = '',
                                 autoplay = true,
                                 direction = "up"
                             }) => {
    const [currentIndex, setCurrentIndex] = useState(0)
    const [loadedImages, setLoadedImages] = useState([]);

    const handleNext = () => {
        setCurrentIndex(prevIndex =>
            prevIndex + 1 === images.length ? 0 : prevIndex + 1
        )
    }

    const handlePrevious = () => {
        setCurrentIndex(prevIndex =>
            prevIndex - 1 < 0 ? images.length - 1 : prevIndex - 1
        )
    }

    useEffect(() => {
        loadImages()
        // eslint-disable-next-line
    }, [])

    const loadImages = () => {
        const loadPromises = images.map(image => {
            return new Promise((resolve, reject) => {
                const img = new Image()
                img.src = image
                img.onload = () => resolve(image)
                img.onerror = reject
            })
        })

        Promise.all(loadPromises)
            .then(loadedImages => {
                setLoadedImages(loadedImages)
            })
            .catch(error => console.error("Failed to load images", error))
    }
    useEffect(() => {
        const handleKeyDown = event => {
            if (event.key === "ArrowRight") {
                handleNext()
            } else if (event.key === "ArrowLeft") {
                handlePrevious()
            }
        }

        window.addEventListener("keydown", handleKeyDown)

        // autoplay
        let interval
        if (autoplay) {
            interval = setInterval(() => {
                handleNext()
            }, 3000)
        }

        return () => {
            window.removeEventListener("keydown", handleKeyDown)
            clearInterval(interval)
        }
        // eslint-disable-next-line
    }, [])

    useEffect(() => {}, [])

    const slideVariants = {
        initial: {
            scale: 0,
            opacity: 0,
            rotateX: 45,
        },
        visible: {
            scale: 1,
            rotateX: 0,
            opacity: 1,
            transition: {
                duration: 0.5,
                ease: [0.645, 0.045, 0.355, 1.0],
            },
        },
        exit: {
            scale: 0.7,
            opacity: 0,
            transition: {
                duration: 1
            }
        },
        exitSecond: {
            scale: 0,
            opacity: 0,
            transition: {
                duration: 1
            }
        }
    }

    const areImagesLoaded = loadedImages.length > 0

    return (
        <div
            className={cn(
                " relative md:mx-auto md:max-w-2xl xl:mx-0 xl:-mt-16 xl:-ml-26 ",
                className
            )}
        >
            {areImagesLoaded && children}






            {areImagesLoaded && (
                <AnimatePresence>


                    <motion.img
                        key={upImages ? (currentIndex === 0 ? upImages['first'][0] :  upImages['second'][0]) : ''}
                        initial="initial"
                        animate="visible"
                        exit={"exit"}
                        variants={slideVariants}
                        className={cn(
                            "absolute z-50 left-0 top-[5em] xl:top-[20%] w-[50%] "
                        )}
                        alt={''}
                        src={upImages ? (currentIndex === 0 ? upImages['first'][0] :  upImages['second'][0]) : ''}
                    />


                    <motion.img
                        key={upImages ? (currentIndex === 0 ? upImages['first'][1] :  upImages['second'][1]) : ''}
                        initial="initial"
                        animate="visible"
                        exit={"exit"}
                        variants={slideVariants}
                        className={cn(
                            "absolute z-50  transition-transform",
                            "scale-100",
                            currentIndex === 0 ? " left-[50%] xl:left-[60%] top-[10em] xl:top-[35%] w-[50%] " :  " left-[60%] xl:left-[70%] top-[10em] xl:top-[35%] w-[40%] "

                        )}
                        alt={''}
                        src={upImages ? (currentIndex === 0 ? upImages['first'][1] :  upImages['second'][1]) : ''}
                    />


                    <motion.img
                        key={upImages ? (currentIndex === 0 ? upImages['first'][2] :  upImages['second'][2]) : ''}
                        initial="initial"
                        animate="visible"
                        exit={"exit"}
                        variants={slideVariants}
                        className={cn(
                            "absolute z-50  transition-transform",
                            "scale-100",
                            currentIndex === 0 ? " left-[0%] top-[20em] xl:top-[95%] w-[25%] " :  " left-[25%] top-[13em] xl:top-[75%] w-[50%] "
                        )}
                        alt={''}
                        src={upImages ? (currentIndex === 0 ? upImages['first'][2] :  upImages['second'][2]) : ''}
                    />


                    {
                        currentIndex === 0 && (
                            <motion.img
                                key={upImages ? (currentIndex === 0 ? upImages['first'][3] :  upImages['second'][3]) : ''}
                                initial="initial"
                                animate="visible"
                                exit={"exit"}
                                variants={slideVariants}
                                className={cn(
                                    "absolute z-50 transition-transform left-[65%] xl:left-[70%] top-[20em] xl:top-[100%] w-[35%]",
                                    "scale-100"
                                )}
                                alt={''}
                                src={upImages['first'][3]}
                            />

                        )
                    }




                    <motion.img
                        key={currentIndex}
                        src={loadedImages[currentIndex]}
                        initial="initial"
                        animate="visible"
                        exit={"exit"}
                        variants={slideVariants}
                        className="absolute "
                    />
                </AnimatePresence>
            )}
        </div>
    )
}
