import { motion } from "framer-motion";
import { AnimatedDiv, TypingText } from "../../components/animations/world/custom-texts";
import {Button} from "./button";
import {CalendarIcon, PlayCircleIcon} from "@heroicons/react/24/outline";
import {useTranslation} from "react-i18next";
import {CALL_LINK, HOW_IT_WORK} from "../../constants/urls";

const staggerContainer = (staggerChildren, delayChildren) => ({
    hidden: {},
    show: {
        transition: {
            staggerChildren,
            delayChildren,
        },
    },
});

const fadeIn = (direction, type, delay, duration) => ({
    hidden: {
        x: direction === 'left' ? 100 : direction === 'right' ? -100 : 0,
        y: direction === 'up' ? 100 : direction === 'down' ? -100 : 0,
        opacity: 0,
    },
    show: {
        x: 0,
        y: 0,
        opacity: 1,
        transition: {
            type,
            delay,
            duration,
            ease: 'easeOut',
        },
    },
});

const World = ({componentDetail}) => {


    const { t } = useTranslation();
    const textVariant2 = {
        hidden: {
            opacity: 0,
            y: 20,
        },
        show: {
            opacity: 1,
            y: 0,
            transition: {
                type: 'tween',
                ease: 'easeIn',
            },
        },
    };

    return (
        <section className={`sm:p-16 xs:p-8 px-6 py-12 relative z-10 bg-indigo-950`}>
            <motion.div
                variants={staggerContainer}
                initial="hidden"
                whileInView="show"
                viewport={{ once: false, amount: 0.25 }}
                className={`2xl:max-w-[1280px] w-full mx-auto flex flex-col`}
            >
                <TypingText title={componentDetail.subtitle} textStyles="text-center text-white/75" />
                <motion.h2
                    variants={textVariant2}
                    initial="hidden"
                    whileInView="show"
                    className={`mt-[8px] text-white font-semibold   text-center mx-auto max-w-4xl text-2xl md:text-6xl `}
                >
                    {componentDetail.title}
                </motion.h2>

                <AnimatedDiv textStyles="mt-[40px] text-center  mx-auto max-w-3xl">

                </AnimatedDiv>


                <motion.h2
                    variants={textVariant2}
                    initial="hidden"
                    whileInView="show"
                    className={`mt-8  md:flex   md:justify-center md:items-center gap-x-2`}
                >
                    <Button  href={CALL_LINK} target={'_blank'} className={'w-full md:w-auto'}>
                        <CalendarIcon  className={'h-5 w-5'} />
                        <span className="ml-2.5">{t("app.common.book")}</span>
                    </Button>

                    <Button variant={'outline'}  href={HOW_IT_WORK} color={'white'} className={'mt-4 w-full md:w-auto md:mt-0'}>
                        <PlayCircleIcon  className={'h-6 w-6'} />
                        <span className="ml-2.5">{t("app.watch_video")}</span>
                    </Button>
                </motion.h2>


                <motion.div
                    variants={fadeIn("up", "tween", 0.3, 1)}
                    className="relative mt-8 xl:mt-[68px] flex w-full xl:h-[550px]"
                >
                    <img src={componentDetail.world} alt="map" className="w-full h-full object-contain xl:object-cover" />
                    <div className="absolute bottom-28 xl:bottom-20 right-20 w-[35px] h-[35px]  xl:w-[70px] xl:h-[70px]  rounded-full bg-solitude-100">
                        <img src={componentDetail.avatar_1} alt="people" className="w-full h-full" />
                    </div>
                    <div className="absolute top-28 xl:top-10 left-20 w-[35px] h-[35px]  xl:w-[70px] xl:h-[70px] rounded-full bg-solitude-100">
                        <img src={componentDetail.avatar_2} alt="people" className="w-full h-full" />
                    </div>
                    <div className="absolute top-1/2   left-[45%] w-[35px] h-[35px]  xl:w-[70px] xl:h-[70px] rounded-full bg-solitude-100">
                        <img src={componentDetail.avatar_3} alt="people" className="w-full h-full" />
                    </div>
                </motion.div>

            </motion.div>
        </section>
    )
};

export default World;
