import {useEffect, useState} from "react";
import {useNavigate, useParams} from "react-router-dom";
import Layout from "../../components/layout/layout";
import Loading from "../../components/common/loading";


function GenericPage({siteConfig, storyBlok, language}) {

    const [pageDetail, setPageDetail] = useState(false);
    const [isLoadingPage, setLoadingPage] = useState(true);

    let navigate = useNavigate();

    let {page}  = useParams();


    useEffect(() => {
        setLoadingPage(true);
        storyBlok.get(`cdn/stories/pages/generic/`+page, {"language": language})
            .then(response => {
                setPageDetail(response.data.story.content.body);
                setLoadingPage(false);
            }).catch(error => {
                navigate("/")
        });

    }, [page, storyBlok, navigate, language]);


    if(isLoadingPage){
        return (<><Loading /></>);
    }

    const pageData = (pageDetail ?? [])?.find(r => r.component === 'simple-page') ?? false

  return (
      <Layout bg={'bg-white'} siteConfig={siteConfig}>


          <div className="spx-6 py-44 sm:py-40 lg:px-8 -mt-20 bg-indigo-950">
              <div className="mx-auto max-w-2xl text-center">
                  <h2 className="text-4xl font-bold tracking-tight text-white sm:text-6xl">{pageData.title ?? ''}</h2>
              </div>
          </div>

          <div className="mx-auto  max-w-7xl px-6 my-10">
              <div className="mt-6  richText" dangerouslySetInnerHTML={{__html:  storyBlok.richTextResolver.render(pageData.description ?? [])}} />
          </div>
      </Layout>
  );
}






export default GenericPage;
