
import { motion } from "framer-motion";


const staggerContainer = (staggerChildren, delayChildren) => ({
    hidden: {},
    show: {
        transition: {
            staggerChildren,
            delayChildren,
        },
    },
});

const textVariant2 = {
    hidden: {
        opacity: 0,
        y: 20,
    },
    show: {
        opacity: 1,
        y: 0,
        transition: {
            type: 'tween',
            ease: 'easeIn',
        },
    },
};


const Testimonials = ({componentDetail, className = 'pt-[20rem] sm:pt-[40rem]  md:pt-[55rem] lg:pt-[15rem] xl:pt-[15rem] bg-slate-900 pb-52'}) => {
    return (
        <section className={className}>
            <div className="mx-auto max-w-7xl px-6 lg:px-8">
                <motion.div
                    variants={staggerContainer}
                    initial="hidden"
                    whileInView="show"
                    viewport={{ once: false, amount: 0.25 }}
                    className="mx-auto grid max-w-2xl grid-cols-1 lg:mx-0 lg:max-w-none lg:grid-cols-2"
                >
                    <div className="flex flex-col pb-10 sm:pb-16 lg:pb-0 lg:pr-8 xl:pr-20">
                        <motion.img
                            variants={textVariant2}
                            initial="hidden"
                            whileInView="show"
                            className="h-12 self-start grayscale" src={componentDetail.logo_1} alt="" />
                        <motion.figure
                            variants={textVariant2}
                            initial="hidden"
                            whileInView="show"
                            className="mt-10 flex flex-auto flex-col justify-between">
                            <blockquote className="text-lg leading-8 text-white">
                                <p>
                                    {componentDetail.description_1}
                                </p>
                            </blockquote>
                            <figcaption className="mt-10 flex items-center gap-x-6">
                                <img
                                    className="h-14 w-14 rounded-full bg-gray-800"
                                    src={componentDetail.avatar_1}
                                    alt=""
                                />
                                <div className="text-base">
                                    <div className="font-semibold text-white">{componentDetail.name_1}</div>
                                    <div className="mt-1 text-gray-400">{componentDetail.position_1}</div>
                                </div>
                            </figcaption>
                        </motion.figure>
                    </div>
                    <div className="flex flex-col border-t border-white/10 pt-10 sm:pt-16 lg:border-l lg:border-t-0 lg:pl-8 lg:pt-0 xl:pl-20">
                        <motion.img
                            variants={textVariant2}
                            initial="hidden"
                            whileInView="show"
                            className="h-12 self-start grayscale" src={componentDetail.logo_2} alt="" />
                        <motion.figure
                            variants={textVariant2}
                            initial="hidden"
                            whileInView="show"
                            className="mt-10 flex flex-auto flex-col justify-between">
                            <blockquote className="text-lg leading-8 text-white">
                                <p>
                                    {componentDetail.description_2}
                                </p>
                            </blockquote>
                            <figcaption className="mt-10 flex items-center gap-x-6">
                                <img
                                    className="h-14 w-14 rounded-full bg-gray-800"
                                    src={componentDetail.avatar_2}
                                    alt=""
                                />
                                <div className="text-base">
                                    <div className="font-semibold text-white">{componentDetail.name_2}</div>
                                    <div className="mt-1 text-gray-400">{componentDetail.position_2}</div>
                                </div>
                            </figcaption>
                        </motion.figure>
                    </div>
                </motion.div>
            </div>
        </section>

    );
}
export default Testimonials;
