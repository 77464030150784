import { Tab } from '@headlessui/react'
import clsx from 'clsx'
import {Container} from "../layout/container";
import {TypingText} from "../animations/world/custom-texts";
import { motion } from "framer-motion";

const staggerContainer = (staggerChildren, delayChildren) => ({
    hidden: {},
    show: {
        transition: {
            staggerChildren,
            delayChildren,
        },
    },
});

const textVariant2 = {
    hidden: {
        opacity: 0,
        y: 20,
    },
    show: {
        opacity: 1,
        y: 0,
        transition: {
            type: 'tween',
            ease: 'easeIn',
        },
    },
};


function Feature({ feature, isActive, index, className, ...props }) {
    return (
        <motion.div
            variants={textVariant2}
            initial="hidden"
            whileInView="show"
            className={clsx(className, !isActive && 'opacity-75 hover:opacity-100')}
            {...props}
        >
            <div
                className={clsx(
                    'flex h-11 w-11 flex-none rounded-lg items-center justify-center mb-4',
                    isActive ? 'bg-indigo-950' : 'bg-indigo-300',
                )}
            >
                <img className={'h-9 w-9 p-1'} src={feature.icon}  alt={''} />
            </div>
            <p className="mt-2 font-display text-xl text-slate-900">
                {feature.name}
            </p>
            <p className="mt-4 text-sm text-slate-600">{feature.text}</p>
        </motion.div>
    )
}

function FeaturesMobile({componentDetail}) {
    return (
        <div className="-mx-4 mt-20 flex flex-col gap-y-10 overflow-hidden px-4 sm:-mx-6 sm:px-6 lg:hidden">
            {componentDetail.cards.map((feature) => (
                <div key={feature._uid}>
                    <Feature feature={{
                        ...feature,
                        name: feature.headline
                    }} className="mx-auto max-w-2xl" isActive />
                    <div className="relative mt-10 pb-10">
                        <div className="relative mx-auto  overflow-hidden rounded-xl bg-white shadow-lg shadow-slate-900/5 ring-1 ring-slate-500/10">
                            <img
                                className="w-full"
                                src={feature.image.filename}
                                alt=""
                                sizes="52.75rem"
                            />
                        </div>
                    </div>
                </div>
            ))}
        </div>
    )
}

function FeaturesDesktop({componentDetail}) {
    return (
        <Tab.Group as="div" className="hidden lg:mt-20 lg:block">
            {({ selectedIndex }) => (
                <>
                    <Tab.List className="grid grid-cols-3 gap-x-8">
                        {componentDetail.cards.map((feature, featureIndex) => (
                            <Feature
                                key={feature._uid}
                                index={featureIndex}
                                feature={{
                                    ...feature,
                                    name: (
                                        <Tab className="ui-not-focus-visible:outline-none">
                                            <span className="absolute inset-0" />
                                            {feature.headline}
                                        </Tab>
                                    ),
                                }}
                                isActive={featureIndex === selectedIndex}
                                className="relative"
                            />
                        ))}
                    </Tab.List>
                    <Tab.Panels className="relative mt-20 overflow-hidden rounded-4xl bg-indigo-100 px-14 py-16 xl:px-16">
                        <div className="-mx-5 flex">
                            {componentDetail.cards.map((feature, featureIndex) => (
                                <Tab.Panel
                                    static
                                    key={feature._uid}
                                    className={clsx(
                                        'px-5 transition duration-500 ease-in-out ui-not-focus-visible:outline-none',
                                        featureIndex !== selectedIndex && 'opacity-60',
                                    )}
                                    style={{ transform: `translateX(-${selectedIndex * 100}%)` }}
                                    aria-hidden={featureIndex !== selectedIndex}
                                >
                                    <div className="w-[52.75rem] overflow-hidden rounded-xl bg-white shadow-lg shadow-slate-900/5 ring-1 ring-slate-500/10">
                                        <img
                                            className="w-full"
                                            src={feature.image.filename}
                                            alt=""
                                            sizes="52.75rem"
                                        />
                                    </div>
                                </Tab.Panel>
                            ))}
                        </div>
                        <div className="pointer-events-none absolute inset-0 rounded-4xl ring-1 ring-inset ring-slate-900/10" />
                    </Tab.Panels>
                </>
            )}
        </Tab.Group>
    )
}
export default function FeatureManage({componentDetail}) {


    return (

        <>


            <section
                id="secondary-features"
                className=" pt-[30rem] sm:pt-[50rem] md:pt-[60rem] lg:pt-[8rem] xl:pt-[15rem] 2xl:pt-[20rem]   bg-indigo-200"
            >

                <Container>

                        <motion.div
                            variants={staggerContainer}
                            initial="hidden"
                            whileInView="show"
                            viewport={{ once: false, amount: 0.25 }}
                            className="mx-auto max-w-2xl md:text-center"
                        >
                            <TypingText title={componentDetail.group} textStyles="text-indigo-950 " />
                            <motion.p
                                variants={textVariant2}
                                initial="hidden"
                                whileInView="show"
                                className="mt-2 font-display text-3xl font-bold tracking-tight text-indigo-950 sm:text-4xl">
                                {componentDetail.title}
                            </motion.p>
                            <motion.p
                                variants={textVariant2}
                                initial="hidden"
                                whileInView="show"
                                className="mt-4   text-lg tracking-tight text-indigo-950/75">
                                {componentDetail.description}
                            </motion.p>
                        </motion.div>



                    <FeaturesMobile componentDetail={componentDetail} />
                    <FeaturesDesktop componentDetail={componentDetail} />
                </Container>
            </section>
        </>
    )
}
