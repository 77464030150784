import {stagger, useAnimate} from "framer-motion";
import {BackgroundView} from "../animations/scroll/card";
import { FeatureTitle } from "../animations/scroll/title";
import { useFeatureStore } from "../animations/scroll/store";
import React, {Fragment,  useEffect, useState } from "react";
import { useHidePageOverflow } from "../animations/scroll/toggle-page-overflow";
import { useEscapePress } from "../animations/scroll/use-escape-press";
import clsx from "clsx";
import {ArrowRightIcon, PlayCircleIcon} from "@heroicons/react/24/outline";
import {Button} from "./button";
import VideoPopup from "./video-popup";



function PlatformFeatures({componentDetail}) {


    const [isOpenVideo, setOpenVideo] = useState(false);
    const [videoLink, setVideoLink] = useState(false);


    const [scope, animate] = useAnimate();
    const fullscreenFeature = useFeatureStore((state) => state.fullscreenFeature);
    const lastFullscreenFeature = useFeatureStore(
        (state) => state.lastFullscreenFeature
    );
    const setFullscreenFeature = useFeatureStore(
        (state) => state.setFullscreenFeature
    );

    const onEscapePress = () => {
        if (fullscreenFeature) setFullscreenFeature(null);
    };

    useEscapePress(onEscapePress);
    useHidePageOverflow(!!fullscreenFeature);

    useEffect(() => {
        if (fullscreenFeature) {
            animate([
                [
                    ".feature-title",
                    { opacity: 0, x: "-200px" },
                    { duration: 0.3, delay: stagger(0.05) },
                ],
                [
                    `.visual-${lastFullscreenFeature}`,
                    { opacity: 1, scale: 1, pointerEvents: "auto" },
                    { at: "<" },
                ],
                [".active-card .gradient", { opacity: 0, scale: 0 }, { at: "<" }],
                [".active-card .show-me-btn", { opacity: 0 }, { at: "<" }],
                [
                    ".back-to-site-btn",
                    { opacity: 1, y: "0px" },
                    { at: "<", duration: 0.3 },
                ],
            ]);
        } else {
            animate([
                [
                    ".feature-title",
                    { opacity: 1, x: "0px" },
                    { duration: 0.3, delay: stagger(0.05) },
                ],
                [
                    `.visual-${lastFullscreenFeature}`,
                    { opacity: 0, scale: 0.75, pointerEvents: "none" },
                    { at: "<" },
                ],
                [".active-card .gradient", { opacity: 1, scale: 1 }, { at: "<" }],
                [
                    ".back-to-site-btn",
                    { opacity: 0, y: "300px" },
                    { at: "<", duration: 0.3 },
                ],
                [".active-card .show-me-btn", { opacity: 1 }],
            ]);
        }
    }, [animate, fullscreenFeature, lastFullscreenFeature]);

    return (

        <>

            <div className=" bg-solitude-100 ">
                <div ref={scope} className={'mx-auto max-w-7xl px-4'}>





                    <div className="flex w-full items-start">
                        <div className="w-full   xl:pt-[50vh] xl:pb-[25vh] xl:basis-5/12">
                            <ul>
                                {(componentDetail?.cards ?? []).map((card) => (
                                    <li key={card._uid} className={' xl:px-0 xl:py-16'}>
                                        <FeatureTitle id={card._uid}>
                                            <Feature
                                                feature={card}
                                                className=""
                                            />


                                            <div className={'mt-6 flex items-center gap-x-2'}>

                                                {
                                                    card.video_link !== '' && (
                                                        <Button  color={'indigoDark'} onClick={() => {
                                                            setOpenVideo(true);
                                                            setVideoLink(card.video_link);
                                                        }}>
                                                            <PlayCircleIcon  className={'h-6 w-6'} />
                                                        </Button>
                                                    )
                                                }


                                                {card.button.map( (button, index) => (
                                                    <Fragment key={index}>
                                                        <Button variant={'outline'} href={button.link.url} color={'indigoDark'}  className={'items-center'}>

                                                            <span className="mr-2.5">{button.label}</span>
                                                            <ArrowRightIcon className={'h-4 w-4'} />

                                                        </Button>
                                                    </Fragment>
                                                ))}


                                            </div>

                                            <div className="flex xl:hidden relative  mt-10">
                                                <div className="absolute -inset-x-4 bottom-0 top-8 bg-black/3 sm:-inset-x-6" />
                                                <div className="relative mx-auto w-full overflow-hidden">
                                                    <img
                                                        className="w-full"
                                                        src={card.background}
                                                        alt=""
                                                        sizes="52.75rem"
                                                    />
                                                </div>
                                            </div>




                                        </FeatureTitle>


                                    </li>
                                ))}
                            </ul>
                        </div>
                        <div className="sticky top-0 flex h-screen w-full items-center hidden xl:flex xl:basis-8/12">
                            <div className="relative aspect-square w-full rounded-2xl  [&:has(>_.active-card)]:bg-transparent">
                                {(componentDetail?.cards ?? []).map((card) => (
                                    <BackgroundView id={card._uid} key={card._uid} bg={card.background} />
                                ))}
                            </div>
                        </div>
                    </div>
                </div>

            </div>



            { isOpenVideo && (
                <VideoPopup
                    setIsOpen={setOpenVideo}
                    isOpen={isOpenVideo}
                    videoLink={videoLink}
                />
                )

            }

        </>
    );
}


function Feature({ feature, className, ...props }) {

    return (
        <div
            className={clsx(className)}
            {...props}
        >
            <div className={clsx('flex h-11 w-11 flex-none rounded-lg bg-indigo-950 items-center justify-center')}>
                <img className={'h-9 w-9 '} src={feature.icon.filename}  alt={''} />
            </div>
            <h3 className={clsx('mt-6 text-3xl font-bold uppercase  text-indigo-950')}>
                {feature.label}
            </h3>
            <p className="mt-1 font-display text-base text-indigo-950/90">
                {feature.subLabel}
            </p>
            <p className="mt-4 text-sm text-indigo-950/75">{feature.text}</p>
        </div>
    )
}

export default PlatformFeatures;
