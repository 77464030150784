import {useEffect, useState} from "react";
import {useNavigate, useParams} from "react-router-dom";
import Layout from "../../components/layout/layout";
import { motion } from "framer-motion";
import SectionWorld from "../../components/common/section-world";
import {Button} from "../../components/common/button";
import {PlayCircleIcon} from "@heroicons/react/24/outline";
import {useTranslation} from "react-i18next";

import LoadingPage from "../../components/common/loadingPage";
import {HOW_IT_WORK, TRY_DOKLESS} from "../../constants/urls";



function BusinessTypePage({siteConfig, storyBlok, language}) {

    const [pageDetail, setPageDetail] = useState(false);
    const [homeData, setHomeData] = useState(false);

    const [isLoadingPage, setLoadingPage] = useState(true);
    const [isLoadingHome, setLoadingHome] = useState(true);
    const [loadedImage, setLoadedImage] = useState('#');

    let navigate = useNavigate();

    let {page}  = useParams();
    const { t } = useTranslation();

    const staggerContainer = (staggerChildren, delayChildren) => ({
        hidden: {},
        show: {
            transition: {
                staggerChildren,
                delayChildren,
            },
        },
    });

    const textVariant2 = {
        hidden: {
            opacity: 0,
            y: 20,
        },
        show: {
            opacity: 1,
            y: 0,
            transition: {
                type: 'tween',
                ease: 'easeIn',
            },
        },
    };


    useEffect(() => {
        setLoadingPage(true); // Assuming this sets a loading state
        storyBlok.get("cdn/stories/pages/business-type/"+page, {"language": language})
            .then(response => {
                setPageDetail(response.data.story.content.body);
                loadImages({image: response.data.story.content.body.find(r => r.component === 'header').background});
                window.scrollTo(0, 0)

            }).catch(error => {
                    navigate("/")
        });

    }, [page, storyBlok, navigate, language]);

    useEffect(() => {
        storyBlok.get(`cdn/stories/homepage`, {"language": language})
            .then(response => {
                setHomeData(response.data.story.content.body);
                setLoadingHome(false);
            }).catch(error => {
            console.error('Error fetching story:', error);
        });
    }, [page, storyBlok, navigate, language]);

    const loadImages = ({ image }) => {

        // Create an array of promises since Promise.all expects an iterable like an array
        const loadPromises = [new Promise((resolve, reject) => {
            const img = new Image();
            img.src = image;
            img.onload = () => resolve(image); // resolve with the image URL
            img.onerror = reject; // reject on error
        })];

        Promise.all(loadPromises)
            .then((loadedImages) => {
                setLoadedImage(loadedImages); // Assuming this is a state setter
                setLoadingPage(false); // Assuming this sets a loading state
            })
            .catch((error) => console.error("Failed to load images", error));
    };


    const header =  pageDetail ? (pageDetail ?? [])?.find(r => r.component === 'header') ?? false : false
    const featureCompany =  pageDetail ? (pageDetail ?? [])?.find(r => r.component === 'feature_company') ?? false : false

  return (
      <div className={'relative'}>
          {
              (isLoadingHome || isLoadingPage) && (
                  <LoadingPage />
              )
          }

          <Layout siteConfig={siteConfig}>

              <div className="relative h-screen isolate overflow-hidden pt-14 -mt-20  ">

                  <img
                      src={loadedImage}
                      alt=""
                      className="absolute inset-0 -z-10 h-full w-full object-cover"
                  />

                  <div className="absolute inset-0 bg-black opacity-60 -z-10" />
                  <div className="absolute -inset-x-20 bottom-0 bg-gradient-to-t -z-10 from-black pt-[25%]" />


                  <div className="mx-auto max-w-3xl py-32 sm:py-48 lg:py-56 z-50 ">
                      <div className="text-center">
                          <h1 className="text-3xl font-bold tracking-tight text-white md:text-4xl px-6 lg:px-8">
                              {header?.title ?? ''}
                          </h1>
                          <p className="mt-3 text-lg leading-8 text-gray-300 px-6 lg:px-8">
                              {header?.description ?? ''}
                          </p>


                          <motion.h2
                              variants={textVariant2}
                              initial="hidden"
                              whileInView="show"
                              className={`mt-8  md:flex   md:justify-center md:items-center gap-x-2 px-6 lg:px-8`}
                          >
                              <Button   href={TRY_DOKLESS} className={'w-full md:w-auto'}>
                                  <span >{t("menu.try_dokless")}</span>
                              </Button>


                              <Button variant={'outline'}  href={HOW_IT_WORK} color={'white'} className={'mt-4 w-full md:w-auto md:mt-0'}>
                                  <PlayCircleIcon  className={'h-6 w-6'} />
                                  <span className="ml-2.5">{t("app.watch_video")}</span>
                              </Button>
                          </motion.h2>

                      </div>
                  </div>


                  <div
                      className="absolute inset-x-0 top-[calc(100%-13rem)] -z-10 transform-gpu overflow-hidden blur-3xl sm:top-[calc(100%-30rem)]"
                      aria-hidden="true"
                  >
                      <div
                          className="relative left-[calc(50%+3rem)] aspect-[1155/678] w-[36.125rem] -translate-x-1/2 bg-gradient-to-tr from-[#ff80b5] to-[#9089fc] opacity-20 sm:left-[calc(50%+36rem)] sm:w-[72.1875rem]"
                          style={{
                              clipPath:
                                  'polygon(74.1% 44.1%, 100% 61.6%, 97.5% 26.9%, 85.5% 0.1%, 80.7% 2%, 72.5% 32.5%, 60.2% 62.4%, 52.4% 68.1%, 47.5% 58.3%, 45.2% 34.5%, 27.5% 76.7%, 0.1% 64.9%, 17.9% 100%, 27.6% 76.8%, 76.1% 97.7%, 74.1% 44.1%)',
                          }}
                      />
                  </div>
              </div>






              <div className="bg-solitude py-24 sm:py-32">
                  <div className="mx-auto max-w-7xl px-6 lg:px-8">
                      <div className="mx-auto max-w-2xl lg:text-center">
                          <p className="mt-2 text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">
                              {featureCompany.title}
                          </p>
                          <p className="mt-6 text-lg leading-8 text-gray-600">
                              {featureCompany.description}
                          </p>
                      </div>
                      <div className="mx-auto mt-16 max-w-2xl sm:mt-20 lg:mt-24 lg:max-w-none">
                          <dl className="grid max-w-xl grid-cols-1 gap-x-8 gap-y-16 lg:max-w-none lg:grid-cols-3">
                              {[
                                  {name: featureCompany.item_1, description: featureCompany.description_1},
                                  {name: featureCompany.item_2, description: featureCompany.description_2},
                                  {name: featureCompany.item_3, description: featureCompany.description_3},
                              ].map((feature) => (
                                  <div key={feature.name} className="flex flex-col rounded-2xl bg-white shadow-md px-5 py-10 text-center">
                                      <dt className="text-base leading-7 font-semibold  tracking-tight  text-indigo-950">
                                          {feature.name}
                                      </dt>
                                      <dd className="mt-6 text-base leading-7 text-indigo-950/75">
                                          <p className="flex-auto">{feature.description}</p>
                                      </dd>
                                  </div>
                              ))}
                          </dl>
                      </div>
                  </div>
              </div>


              {
                  (header?.features ?? []).map(feature => (
                      feature.type === 'white' ? (
                          <div className="overflow-hidden bg-solitude-100 py-24 sm:py-32">
                              <div className="mx-auto max-w-7xl px-6 lg:px-8">
                                  <motion.div
                                      variants={staggerContainer}
                                      initial="hidden"
                                      whileInView="show"
                                      viewport={{ once: false, amount: 0.25 }}
                                      className="mx-auto grid max-w-2xl grid-cols-1 gap-x-8 gap-y-16 sm:gap-y-20 lg:mx-0 lg:max-w-none lg:grid-cols-2 items-center">


                                      <div className="lg:pr-8 lg:pt-4">
                                          <div className="lg:max-w-lg">
                                              <motion.p
                                                  variants={textVariant2}
                                                  initial="hidden"
                                                  whileInView="show"
                                                  className="mt-2 text-3xl font-bold tracking-tight text-indigo-950 sm:text-4xl">{feature.title}</motion.p>
                                              <motion.p
                                                  variants={textVariant2}
                                                  initial="hidden"
                                                  whileInView="show"
                                                  className="mt-6 text-lg leading-8 text-indigo-950/75">{feature.description}</motion.p>
                                          </div>
                                      </div>
                                      <motion.video
                                          variants={textVariant2}
                                          initial="hidden"
                                          whileInView="show"
                                          playsinline="" autoplay="autoplay" loop="true" muted="muted" name="media" className={'mb-[-5%] rounded-xl shadow-2xl'}>
                                          <source src={feature.video_link} type="video/mp4"/>
                                      </motion.video>

                                  </motion.div>
                              </div>
                          </div>
                      ) : (
                          <div className="overflow-hidden bg-slate-900 py-24 sm:py-32">
                              <div className="mx-auto max-w-7xl px-6 lg:px-8">
                                  <motion.div
                                      variants={staggerContainer}
                                      initial="hidden"
                                      whileInView="show"
                                      viewport={{ once: false, amount: 0.25 }}
                                      className="mx-auto grid max-w-2xl grid-cols-1 gap-x-8 gap-y-16 sm:gap-y-20 lg:mx-0 lg:max-w-none lg:grid-cols-2 items-center">
                                      <div className="lg:ml-auto lg:pl-4 lg:pt-4">
                                          <div className="lg:max-w-lg">
                                              <motion.p
                                                  variants={textVariant2}
                                                  initial="hidden"
                                                  whileInView="show"
                                                  className="mt-2 text-3xl font-bold tracking-tight text-white sm:text-4xl">{feature.title}</motion.p>
                                              <motion.p
                                                  variants={textVariant2}
                                                  initial="hidden"
                                                  whileInView="show"
                                                  className="mt-6 text-lg leading-8 text-white/75">{feature.description}</motion.p>

                                          </div>
                                      </div>
                                      <div className="flex items-start justify-end lg:order-first">
                                          <motion.video
                                              variants={textVariant2}
                                              initial="hidden"
                                              whileInView="show"
                                              playsinline="" autoplay="autoplay" loop="true" muted="muted" name="media" className={'mb-[-5%] rounded-xl shadow-2xl'}>
                                              <source src={feature.video_link} type="video/mp4"/>
                                          </motion.video>
                                      </div>
                                  </motion.div>
                              </div>
                          </div>
                      )
                  ))
              }

              {
                  homeData && (
                      <SectionWorld componentDetail={(homeData ?? [])?.find(r => r.component === 'world') ?? false} />
                  )
              }




          </Layout>

      </div>


  );
}






export default BusinessTypePage;
