const CurvedDiv = ({className, color}) => {
    return (
        <div className={className}>
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1440 320">
                <path fill={color} fill-opacity="1" d="M0,128L120,112C240,96,480,64,720,69.3C960,75,1200,117,1320,138.7L1440,160L1440,320L1320,320C1200,320,960,320,720,320C480,320,240,320,120,320L0,320Z" />
            </svg>
        </div>
    );
}
export default CurvedDiv;
