import { useFeatureStore } from "./store"
import { motion } from "framer-motion"
function classNames(...classes) {
    return classes.filter(Boolean).join(" ")
}


const FeatureCardBackground = ({ bg, children, id }) => {
    const inViewFeature = useFeatureStore(state => state.inViewFeature)
    const fullscreenFeature = useFeatureStore(store => store.fullscreenFeature)
    const isFullscreen = fullscreenFeature === id

    return (
            !isFullscreen && (
                <motion.div
                    className={classNames(
                        "absolute inset-0 h-full w-full rounded-2xl transition-opacity",

                        inViewFeature === id
                            ? "active-card opacity-100"
                            : "pointer-events-none opacity-0"
                    )}
                >
                    <img
                        src={bg}
                        alt="Product screenshot"
                        className=" h-full w-full object-cover object-left"
                    />
                    <div className="absolute -inset-y-px right-0 z-10 w-1/2   bg-gradient-to-l from-solitude-100"/>
                    {children}

                </motion.div>
            )
    )
}


export const BackgroundView = ({ id, bg }) => {
    return (
        <FeatureCardBackground id={id} bg={bg}>
            <span />
        </FeatureCardBackground>
    )
}

