import { motion } from "framer-motion";


 const textContainer = {
    hidden: {
        opacity: 0,
    },
    show: (i = 1) => ({
        opacity: 1,
        transition: { staggerChildren: 0.1, delayChildren: i * 0.1 },
    }),
};

 const textVariant2 = {
    hidden: {
        opacity: 0,
        y: 20,
    },
    show: {
        opacity: 1,
        y: 0,
        transition: {
            type: 'tween',
            ease: 'easeIn',
        },
    },
};

export const TypingText = ({ title, textStyles }) => (
    <motion.p
        variants={textContainer}
        className={`text-base leading-7 tracking-tight  uppercase ${textStyles}`}
    >
        {Array.from(title).map((letter, index) => (
            <motion.span variants={textVariant2} key={index}>
                {letter === "" ? "\u00A0" : letter}
            </motion.span>
        ))}
    </motion.p>
);

export const TitleText = ({ title, textStyles }) => (
    <motion.h2
        variants={textVariant2}
        initial="hidden"
        whileInView="show"
        className={`mt-[8px] font-semibold  text-xl md:text-6xl text-white ${textStyles}`}
    >
        {title}
    </motion.h2>
);

export const AnimatedDiv = ({ children, textStyles }) => (
    <motion.h2
        variants={textVariant2}
        initial="hidden"
        whileInView="show"
        className={`mt-[8px]  ${textStyles}`}
    >
        {children}
    </motion.h2>
);
