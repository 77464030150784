import { Dialog, Transition } from '@headlessui/react'
import { Fragment } from 'react'
import {XMarkIcon} from "@heroicons/react/24/outline";

export default function YtVideoPopup({isOpen, setIsOpen, videoLink}) {



    return (
        <>

            <Transition appear show={isOpen} as={Fragment}>
                <Dialog as="div" className="relative z-10" onClose={() => {setIsOpen(false)}}>
                    <Transition.Child
                        as={Fragment}
                        enter="ease-out duration-300"
                        enterFrom="opacity-0"
                        enterTo="opacity-100"
                        leave="ease-in duration-200"
                        leaveFrom="opacity-100"
                        leaveTo="opacity-0"
                    >
                        <Dialog.Overlay className="fixed inset-0 bg-gray-900 bg-opacity-30 z-5 transition-opacity" />
                    </Transition.Child>


                    <div className="fixed inset-0 overflow-y-auto">
                        <div className="flex min-h-full items-center justify-center p-4 text-center">
                            <Transition.Child
                                as={Fragment}
                                enter="ease-out duration-300"
                                enterFrom="opacity-0 scale-95"
                                enterTo="opacity-100 scale-100"
                                leave="ease-in duration-200"
                                leaveFrom="opacity-100 scale-100"
                                leaveTo="opacity-0 scale-95"
                            >
                                <div className="inline-block w-full max-w-4xl my-8 text-left align-middle transition-all transform ">

                                    <div className="flex justify-between    items-center">
                                        <div>
                                        </div>
                                        <div>
                                            <button className=" border-0" onClick={() => {setIsOpen(false)}}><XMarkIcon className="w-6 h-6 p-1 fill-current rounded-full   text-white" /></button>
                                        </div>
                                    </div>

                                    <div className={'max-w-7xl'}>
                                        <iframe width="100%" height="500" src={videoLink}
                                                title="YouTube video player" frameBorder="0"
                                                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                                                allowFullScreen />
                                    </div>
                                </div>
                            </Transition.Child>
                        </div>
                    </div>
                </Dialog>
            </Transition>
        </>
    )
}
