import { motion } from "framer-motion";
import {TypingText} from "../animations/world/custom-texts";


const staggerContainer = (staggerChildren, delayChildren) => ({
    hidden: {},
    show: {
        transition: {
            staggerChildren,
            delayChildren,
        },
    },
});

const textVariant2 = {
    hidden: {
        opacity: 0,
        y: 20,
    },
    show: {
        opacity: 1,
        y: 0,
        transition: {
            type: 'tween',
            ease: 'easeIn',
        },
    },
};

export default function FeatureBuild({bg, color, componentDetail}) {
    return (
        <div className={'overflow-hidden py-24 sm:py-32 ' + color}>
            <div className="mx-auto max-w-7xl px-6 lg:px-8">
                <div className="mx-auto grid  max-w-2xl grid-cols-1 gap-x-8 gap-y-16 sm:gap-y-20 lg:mx-0 lg:max-w-none lg:grid-cols-2">
                    <div className="lg:ml-auto lg:pl-4 lg:pt-4">

                        <motion.div
                            variants={staggerContainer}
                            initial="hidden"
                            whileInView="show"
                            viewport={{ once: false, amount: 0.25 }}
                            className="lg:max-w-lg"
                        >

                            <TypingText title={componentDetail.group} textStyles="text-indigo-950 text-left" />

                            <motion.p
                                variants={textVariant2}
                                initial="hidden"
                                whileInView="show"

                                className="mt-2 text-3xl font-bold tracking-tight text-indigo-950 sm:text-4xl">{componentDetail.title}</motion.p>
                            <motion.p
                                variants={textVariant2}
                                initial="hidden"
                                whileInView="show"

                                className="mt-6 text-lg leading-8 text-indigo-950/75">{componentDetail.description}
                            </motion.p>




                            <motion.dl
                                variants={textVariant2}
                                initial="hidden"
                                whileInView="show"
                                className="mt-10 max-w-xl space-y-8 text-base leading-7 text-gray-600 lg:max-w-none">
                                <div  className="relative pl-9">
                                    <p className="inline text-indigo-950/75">
                                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24"
                                             strokeWidth="1.5" stroke="currentColor" aria-hidden="true"
                                             data-slot="icon" className="absolute left-1 top-1 h-5 w-5 text-indigo-950/75">
                                            <path stroke-linecap="round" stroke-linejoin="round" d="M21 12a9 9 0 1 1-18 0 9 9 0 0 1 18 0Z" />
                                        </svg>
                                        {componentDetail.explain_1}
                                    </p>
                                </div>

                                <div  className="relative pl-9">
                                    <p className="inline text-indigo-950/75">
                                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24"
                                             strokeWidth="1.5" stroke="currentColor" aria-hidden="true"
                                             data-slot="icon" className="absolute left-1 top-1 h-5 w-5 text-indigo-950/75">
                                            <path stroke-linecap="round" stroke-linejoin="round" d="M21 12a9 9 0 1 1-18 0 9 9 0 0 1 18 0Z" />
                                        </svg>
                                        {componentDetail.explain_2}
                                    </p>
                                </div>

                                <div  className="relative pl-9">
                                    <p className="inline text-indigo-950/75">
                                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24"
                                             strokeWidth="1.5" stroke="currentColor" aria-hidden="true"
                                             data-slot="icon" className="absolute left-1 top-1 h-5 w-5 text-indigo-950/75">
                                            <path stroke-linecap="round" stroke-linejoin="round" d="M21 12a9 9 0 1 1-18 0 9 9 0 0 1 18 0Z" />
                                        </svg>
                                        {componentDetail.explain_3}
                                    </p>
                                </div>
                            </motion.dl>
                        </motion.div>
                    </div>
                    <div className="flex items-start justify-end lg:order-first">
                        <motion.img
                            variants={textVariant2}
                            initial="hidden"
                            whileInView="show"
                            src={bg}
                            alt="Product screenshot"
                            className="absolute left-0 object-right w-full lg:max-w-2xl xl:max-w-3xl xl:-mt-[10%] 2xl:max-w-screen-lg 2xl:-mt-[15%]"
                            width={2432}
                            height={1442}
                        />
                    </div>
                </div>
            </div>
        </div>
    )
}
