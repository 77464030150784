import {NavLink} from "react-router-dom";
import SubMenu from "../layout/sub-menu";

function classNames(...classes) {
    return classes.filter(Boolean).join(" ")
}

export function NavLinks({siteConfig, isDark}) {

  return (siteConfig?.content?.header_nav ?? []).map((data, index) => (

      <>

        {
            data.type === 'normal' && (
                <NavLink
                    key={data.id}
                    to={data.link.url}
                    className={classNames(
                        "relative -mx-3 -my-2  px-3 py-2  text-sm tracking-wide",
                        isDark ?  "text-white/80 transition-colors  delay-150 hover:text-white hover:delay-[0ms]" :
                            "text-indigo-950/80 transition-colors delay-150 hover:text-indigo-950 hover:delay-[0ms]",
                    )}
                >
                    {data.label}
                </NavLink>
            )
        }

        {
            data.type === 'submenu' && (
                <div
                    key={data.id}
                    className={classNames(
                        "relative -mx-3 -my-2  px-3 py-2 text-sm tracking-wide",
                        isDark ?  "text-white/80 transition-colors  delay-150 hover:text-white hover:delay-[0ms]" :
                            "text-indigo-950/80 transition-colors delay-150 hover:text-indigo-950 hover:delay-[0ms]",
                    )}
                >
                  <SubMenu label={data.label} menuDetails={data.submenu} />
                </div>
            )
        }
          {
              data.type === 'simple-submenu' && (
                  <div
                      key={data.id}
                      className={classNames(
                          "relative -mx-3 -my-2  px-3 py-2 text-sm tracking-wide",
                          isDark ?  "text-white/80 transition-colors  delay-150 hover:text-white hover:delay-[0ms]" :
                              "text-indigo-950/80 transition-colors delay-150 hover:text-indigo-950 hover:delay-[0ms]",
                      )}
                  >
                      <SubMenu label={data.label} isSimple={true} menuDetails={data.submenu} />
                  </div>
              )
          }

      </>



  ))
}
