import React, {useState} from "react";
import {BellAlertIcon, XCircleIcon} from "@heroicons/react/24/outline";
import {Link} from "react-router-dom";
import {useTranslation} from "react-i18next";

export default function Cookie() {

    const [status, setStatus] = useState(localStorage.getItem('privacy_status'));
    const { t } = useTranslation();

    return (

        !status && (
            <>

                <div className="fixed inset-x-0 bottom-0">
                    <div className="bg-indigo-950">
                        <div className="max-w-7xl mx-auto py-3 px-3 sm:px-6 lg:px-8">
                            <div className="flex items-center justify-between flex-wrap">
                                <div className="w-0 flex-1 flex items-center">
                                <span className="flex p-2 rounded-lg bg-indigo-800">
                                  <BellAlertIcon className="h-6 w-6 text-white" aria-hidden="true" />
                                </span>
                                    <p className="ml-3 text-sm text-white ">
                                    <span>{t("pages.flag")} {' '}
                                        <Link to="/pages/generic/privacy-policy" className="underline">Cookie policy </Link>
                                    </span>
                                    </p>
                                </div>

                                <div className="order-2 flex-shrink-0 sm:order-3 sm:ml-3">
                                    <button
                                        onClick={() => {
                                            localStorage.setItem('privacy_status', true);
                                            setStatus( true);

                                        }}
                                        type="button"
                                        className="-mr-1 flex p-2 rounded-md hover:bg-indigo-500 focus:outline-none focus:ring-2 focus:ring-white sm:-mr-2"
                                    >
                                        <span className="sr-only">Dismiss</span>
                                        <XCircleIcon className="h-6 w-6 text-white" aria-hidden="true" />
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </>
        )


    )
}
