import {useEffect, useState} from "react";
import {useNavigate, useParams} from "react-router-dom";
import Layout from "../../components/layout/layout";
import Loading from "../../components/common/loading";
import {Button} from "../../components/common/button";
import {ArrowRightIcon} from "@heroicons/react/24/outline";
import YtVideoPopup from "../../components/common/ytvideo-popup";


function ContactUs({siteConfig, storyBlok, language}) {

    const [pageDetail, setPageDetail] = useState(false);
    let [isOpen, setIsOpen] = useState(false)

    const [isLoadingPage, setLoadingPage] = useState(true);

    let navigate = useNavigate();

    let {page}  = useParams();



    useEffect(() => {
        storyBlok.get("cdn/stories/pages/company/contact-us", {"language": language})
            .then(response => {
                setPageDetail(response.data.story.content.body);
                setLoadingPage(false);
            }).catch(error => {
            navigate("/")
        });

    }, [page, storyBlok, navigate, language]);

    if(isLoadingPage){
        return (<><Loading /></>);
    }

    const header = (pageDetail ?? [])?.find(r => r.component === 'contact_header') ?? false
    const contactUs = (pageDetail ?? [])?.find(r => r.component === 'contact') ?? false

    return (
        <Layout siteConfig={siteConfig}>


            <div className="relative  isolate overflow-hidden pt-14 -mt-20 ">
                <img
                    src={header.background}
                    alt=""
                    className="absolute inset-0 -z-10 h-full w-full object-cover"
                />


                <div className="absolute inset-0 bg-black opacity-60 -z-10" />
                <div className="absolute -inset-x-20 bottom-0 bg-gradient-to-t -z-10 from-black pt-[25%]" />


                <div className="mx-auto max-w-2xl py-32 sm:py-48 lg:py-56 z-50">
                    <div className="text-center">
                        <h1 className="text-4xl font-bold tracking-tight text-white sm:text-6xl">
                            {header.title}
                        </h1>
                        <div className="mt-6 text-lg leading-8 text-gray-300 richText" dangerouslySetInnerHTML={{__html:  storyBlok.richTextResolver.render(header.description)}} />

                    </div>
                </div>
                <div
                    className="absolute inset-x-0 top-[calc(100%-13rem)] -z-10 transform-gpu overflow-hidden blur-3xl sm:top-[calc(100%-30rem)]"
                    aria-hidden="true"
                >
                    <div
                        className="relative left-[calc(50%+3rem)] aspect-[1155/678] w-[36.125rem] -translate-x-1/2 bg-gradient-to-tr from-[#ff80b5] to-[#9089fc] opacity-20 sm:left-[calc(50%+36rem)] sm:w-[72.1875rem]"
                        style={{
                            clipPath:
                                'polygon(74.1% 44.1%, 100% 61.6%, 97.5% 26.9%, 85.5% 0.1%, 80.7% 2%, 72.5% 32.5%, 60.2% 62.4%, 52.4% 68.1%, 47.5% 58.3%, 45.2% 34.5%, 27.5% 76.7%, 0.1% 64.9%, 17.9% 100%, 27.6% 76.8%, 76.1% 97.7%, 74.1% 44.1%)',
                        }}
                    />
                </div>
            </div>







            <div className="bg-solitude-100 py-24 sm:py-32">
                <div className="mx-auto max-w-7xl px-6 lg:px-8">
                    <div className="mx-auto max-w-2xl space-y-16 divide-y divide-gray-100 lg:mx-0 lg:max-w-none">
                        <div className="grid grid-cols-1 gap-x-8 gap-y-10 lg:grid-cols-3">
                            <div>
                                <h2 className="text-3xl font-bold tracking-tight text-indigo-950 sm:text-4xl">{contactUs.title}</h2>
                                <p className="mt-4 text-base leading-8 text-indigo-950/75">
                                    {contactUs.description}
                                </p>
                            </div>
                            <div className="grid grid-cols-1 gap-6 sm:grid-cols-2 lg:col-span-2 lg:gap-8">

                                {
                                    contactUs.contacts.map(contact => (
                                        <div className="rounded-2xl bg-white p-10">
                                            <h3 className="text-2xl font-semibold  tracking-tight  text-indigo-950">{contact.title}</h3>
                                            <div className="mt-6 text-base leading-8 text-indigo-950/75 richText" dangerouslySetInnerHTML={{__html:  storyBlok.richTextResolver.render(contact.description)}} />

                                            <Button  href={contact.link.url} target={'_blank'} className={'flex items-center'}>
                                                <span className="mr-2.5">{contact.button_label}</span>
                                                <ArrowRightIcon  className={'h-4 w-4'} />
                                            </Button>
                                        </div>

                                    ))
                                }
                            </div>
                        </div>

                    </div>
                </div>
            </div>

            <YtVideoPopup
                isOpen={isOpen}
                setIsOpen={setIsOpen}
                videoLink={'https://www.youtube.com/embed/46gG8owMTDc'}
            />

        </Layout>
    );
}






export default ContactUs;
