import backgroundSea from '../../resource/image/slider/bg-sea.png';
import backgroundLand from '../../resource/image/slider/bg-truck.png';
import {ImagesSlider} from "../animations/slider/Images-slider";
import shipSailed from "../../resource/image/slider/notification-ship-sailed.svg";
import shipDateChange from "../../resource/image/slider/notification-ship-date-change.svg";
import inTransit from "../../resource/image/slider/in-transit.svg";
import shipReference from "../../resource/image/slider/booking-ref.svg";
import customsC from '../../resource/image/slider/notification-customs.svg';
import truckStatus from '../../resource/image/slider/truck-status.svg';
import truckLocation from '../../resource/image/slider/location.svg';
import {PlayCircleIcon} from "@heroicons/react/24/outline";
import {Fragment} from "react";
import {Button} from "../common/button";



export default function Header({componentDetail}) {

    const FocusLetter = ({originalString, wordToHighlight}) => {
        const segments = originalString.split(wordToHighlight);
        return (

            <>
                {segments.map((segment, index) => (
                    <Fragment key={index}>
                        {segment}
                        {index < segments.length - 1 && (
                            <span className="relative whitespace-nowrap text-white">
                              <svg
                                  aria-hidden="true"
                                  viewBox="0 0 418 42"
                                  className="absolute left-0 top-2/3 h-[0.58em] w-full fill-indigo-400"
                                  preserveAspectRatio="none"
                              >
                                <path d="M203.371.916c-26.013-2.078-76.686 1.963-124.73 9.946L67.3 12.749C35.421 18.062 18.2 21.766 6.004 25.934 1.244 27.561.828 27.778.874 28.61c.07 1.214.828 1.121 9.595-1.176 9.072-2.377 17.15-3.92 39.246-7.496C123.565 7.986 157.869 4.492 195.942 5.046c7.461.108 19.25 1.696 19.17 2.582-.107 1.183-7.874 4.31-25.75 10.366-21.992 7.45-35.43 12.534-36.701 13.884-2.173 2.308-.202 4.407 4.442 4.734 2.654.187 3.263.157 15.593-.78 35.401-2.686 57.944-3.488 88.365-3.143 46.327.526 75.721 2.23 130.788 7.584 19.787 1.924 20.814 1.98 24.557 1.332l.066-.011c1.201-.203 1.53-1.825.399-2.335-2.911-1.31-4.893-1.604-22.048-3.261-57.509-5.556-87.871-7.36-132.059-7.842-23.239-.254-33.617-.116-50.627.674-11.629.54-42.371 2.494-46.696 2.967-2.359.259 8.133-3.625 26.504-9.81 23.239-7.825 27.934-10.149 28.304-14.005.417-4.348-3.529-6-16.878-7.066Z" />
                              </svg>
                              <span className="relative">{wordToHighlight}</span>
                            </span>
                        )}
                    </Fragment>
                ))}
            </>
        )
    }


    return (

        <div className="bg-black  -mt-20 content-center ">

            <div className="relative  h-[980px] sm:h-[1100px]  md:h-[1200px] xl:h-full  isolate overflow-hidden bg-gradient-to-b from-indigo-950/90 ">

                <div className="mx-auto max-w-7xl  mt-16  xl:mt-0 pb-16 pt-10 sm:pb-32 xl:grid xl:grid-cols-2 xl:gap-x-2 xl:px-8 xl:pt-60  xl:pb-8">
                    <div className="px-6 xl::px-0 xl::pt-4 xl::mr-2 ">
                        <div className="mx-auto max-w-2xl">
                            <div className="max-w-full xl:max-w-2xl">
                                <h1 className="mt-10 text-3xl  font-bold tracking-tight text-white xl:text-6xl">
                                    <FocusLetter originalString={componentDetail.headline} wordToHighlight={componentDetail.focus_letter}/>
                                </h1>
                                <p className="mt-6 text-base xl:text-lg  leading-1 tracking-tight text-white">
                                    {componentDetail.subheadline}
                                </p>
                                <div className="mt-10 md:flex md:items-center gap-x-2">
                                    {componentDetail.buttons.map( (button, index) => (
                                        <Fragment key={index}>
                                            {
                                                button.type === 'video' ? (
                                                    <Button variant={'outline'}  href={button.link.url} color={'white'} className={'mt-4 w-full md:w-auto md:mt-0'}>
                                                        <PlayCircleIcon  className={'h-6 w-6'} />
                                                        <span className="ml-2.5">{button.label}</span>
                                                    </Button>
                                                ) : (
                                                    <Button href={button.link.url} className={'w-full md:w-auto'}>
                                                        {button.label}
                                                    </Button>
                                                )
                                            }
                                        </Fragment>
                                    ))}



                                </div>
                            </div>
                        </div>
                    </div>

                    <ImagesSlider overlay={false} images={[backgroundSea, backgroundLand]} upImages={{first: [shipSailed,shipDateChange,inTransit,shipReference ], second: [customsC,truckLocation,truckStatus ]}}/>
                </div>

                <div className="hidden  xl:flex sm:mt-[10%] xl:mt-[10%]  flex justify-center relative rounded-xl overflow-auto   h-[75px] items-center  col-span-2 bg-black">
                    <div
                        className="animate-bounce   flex items-center justify-center">
                        <svg className="w-8 h-8 text-white" fill="none" strokeLinecap="round"
                             strokeLinejoin="round" strokeWidth="1.5" viewBox="0 0 24 24" stroke="currentColor">
                            <path d="M19 14l-7 7m0 0l-7-7m7 7V3" />
                        </svg>
                    </div>
                </div>
            </div>
        </div>


    )
}

