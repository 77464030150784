import {useEffect, useState} from "react";
import {useNavigate, useParams} from "react-router-dom";
import Layout from "../../components/layout/layout";
import Loading from "../../components/common/loading";
import SectionWorld from "../../components/common/section-world";
import {Button} from "../../components/common/button";
import {PlayCircleIcon} from "@heroicons/react/24/outline";
import {useTranslation} from "react-i18next";
import YtVideoPopup from "../../components/common/ytvideo-popup";
import {HOW_IT_WORK, TRY_DOKLESS} from "../../constants/urls";
import {motion} from "framer-motion";


function AboutUs({siteConfig, storyBlok, language}) {

    const [pageDetail, setPageDetail] = useState(false);
    const [homeData, setHomeData] = useState(false);
    let [isOpen, setIsOpen] = useState(false)

    const [isLoadingPage, setLoadingPage] = useState(true);
    const [isLoadingHome, setLoadingHome] = useState(true);

    let navigate = useNavigate();

    let {page}  = useParams();
    const { t } = useTranslation();


    const textVariant2 = {
        hidden: {
            opacity: 0,
            y: 20,
        },
        show: {
            opacity: 1,
            y: 0,
            transition: {
                type: 'tween',
                ease: 'easeIn',
            },
        },
    };



    useEffect(() => {
        storyBlok.get("cdn/stories/pages/company/about-us", {"language": language})
            .then(response => {
                setPageDetail(response.data.story.content.body);
                setLoadingPage(false);
            }).catch(error => {
            navigate("/")
        });

    }, [page, storyBlok, navigate, language]);

    useEffect(() => {
        storyBlok.get(`cdn/stories/homepage`, {"language": language})
            .then(response => {
                setHomeData(response.data.story.content.body);
                setLoadingHome(false);
            }).catch(error => {
            console.error('Error fetching story:', error);
        });
    }, [page, storyBlok, navigate, language]);

    if(isLoadingHome || isLoadingPage){
        return (<><Loading /></>);
    }

    const header = (pageDetail ?? [])?.find(r => r.component === 'section_header') ?? false
    const aboutUs = (pageDetail ?? [])?.find(r => r.component === 'about-us') ?? false
    const team = (pageDetail ?? [])?.find(r => r.component === 'team') ?? false

    return (
        <Layout siteConfig={siteConfig}>


            <div id='about-us' className="relative h-screen isolate overflow-hidden pt-14 -mt-20 ">
                <img
                    src={header.background}
                    alt=""
                    className="absolute inset-0 -z-10 h-full w-full object-cover"
                />


                <div className="absolute inset-0 bg-black opacity-60 -z-10" />
                <div className="absolute -inset-x-20 bottom-0 bg-gradient-to-t -z-10 from-black pt-[25%]" />


                <div className="mx-auto max-w-2xl py-32 sm:py-48 lg:py-56 z-50">
                    <div className="text-center">
                        <h1 className="text-3xl font-bold tracking-tight text-white md:text-4xl px-6 lg:px-8">
                            {header?.title ?? ''}
                        </h1>
                        <p className="mt-3 text-lg leading-8 text-gray-300 px-6 lg:px-8">
                            {header?.description ?? ''}
                        </p>
                        <motion.h2
                            variants={textVariant2}
                            initial="hidden"
                            whileInView="show"
                            className={`mt-8  md:flex   md:justify-center md:items-center gap-x-2 px-6 lg:px-8`}
                        >
                            <Button   href={TRY_DOKLESS} className={'w-full md:w-auto'}>
                                <span >{t("menu.try_dokless")}</span>
                            </Button>


                            <Button variant={'outline'}  href={HOW_IT_WORK} color={'white'} className={'mt-4 w-full md:w-auto md:mt-0'}>
                                <PlayCircleIcon  className={'h-6 w-6'} />
                                <span className="ml-2.5">{t("app.watch_video")}</span>
                            </Button>
                        </motion.h2>
                    </div>
                </div>
                <div
                    className="absolute inset-x-0 top-[calc(100%-13rem)] -z-10 transform-gpu overflow-hidden blur-3xl sm:top-[calc(100%-30rem)]"
                    aria-hidden="true"
                >
                    <div
                        className="relative left-[calc(50%+3rem)] aspect-[1155/678] w-[36.125rem] -translate-x-1/2 bg-gradient-to-tr from-[#ff80b5] to-[#9089fc] opacity-20 sm:left-[calc(50%+36rem)] sm:w-[72.1875rem]"
                        style={{
                            clipPath:
                                'polygon(74.1% 44.1%, 100% 61.6%, 97.5% 26.9%, 85.5% 0.1%, 80.7% 2%, 72.5% 32.5%, 60.2% 62.4%, 52.4% 68.1%, 47.5% 58.3%, 45.2% 34.5%, 27.5% 76.7%, 0.1% 64.9%, 17.9% 100%, 27.6% 76.8%, 76.1% 97.7%, 74.1% 44.1%)',
                        }}
                    />
                </div>
            </div>



            <section  className="overflow-hidden bg-white py-32">
                <div className="mx-auto max-w-7xl px-6 lg:flex lg:px-8">
                    <div className="mx-auto grid max-w-2xl grid-cols-1 gap-x-12 gap-y-16 lg:mx-0 lg:min-w-full lg:max-w-none lg:flex-none lg:gap-y-8">
                        <div className="lg:col-end-1 lg:w-full lg:max-w-lg lg:pb-8">
                            <h2 className="text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">{aboutUs.title}</h2>
                            <div className="mt-6  text-base leading-7 text-gray-600 richText" dangerouslySetInnerHTML={{__html:  storyBlok.richTextResolver.render(aboutUs.description)}} />

                        </div>
                        <div className="flex flex-wrap items-start justify-end gap-6 sm:gap-8 lg:contents">
                            <div className="relative w-0 flex-auto lg:ml-auto lg:w-auto lg:flex-none lg:self-end">
                                <img
                                    src={aboutUs.image_3}
                                    alt=""
                                    className="aspect-[7/5] w-[37rem] max-w-none rounded-2xl bg-gray-50 object-cover"
                                />
                            </div>
                            <div className="contents lg:col-span-2 lg:col-end-2 lg:ml-auto lg:flex lg:w-[37rem] lg:items-start lg:justify-end lg:gap-x-8 hidden md:block">
                                <div className="flex w-96 flex-auto justify-end lg:w-auto lg:flex-none">
                                    <img
                                        src={aboutUs.image_2}
                                        alt=""
                                        className="aspect-[7/5] w-[37rem] max-w-none flex-none rounded-2xl bg-gray-900 object-contain"
                                    />
                                </div>
                                <div className="hidden sm:block sm:w-0 sm:flex-auto lg:w-auto lg:flex-none">
                                    <img
                                        src={aboutUs.image_1}
                                        alt=""
                                        className="aspect-[4/3] w-[24rem] max-w-none rounded-2xl bg-gray-50 object-cover"
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>




            <section className="bg-solitude-100 py-24 md:py-32 lg:py-40" id='team'>
                <div className="mx-auto grid max-w-7xl grid-cols-1 gap-x-8 gap-y-20 px-6 lg:px-8 xl:grid-cols-3">
                    <div className="mx-auto max-w-2xl lg:mx-0">
                        <h2 className="text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">{team.title}</h2>
                        <p className="mt-6 text-lg leading-8 text-gray-600">{team.description}</p>
                    </div>
                    <ul
                        className="mx-auto grid max-w-2xl grid-cols-1 gap-x-6 gap-y-20 sm:grid-cols-2 lg:mx-0 lg:max-w-none lg:gap-x-8 xl:col-span-2"
                    >
                        {team.teams.map((person) => (
                            <li key={person._uid}>
                                <img className="aspect-[3/2] w-full rounded-2xl object-contain bg-black" src={person.image} alt="" />
                                <h3 className="mt-6 text-lg font-semibold leading-8 text-gray-900">{person.fullname}</h3>
                                <p className="text-base leading-7 text-gray-600">{person.position}</p>
                            </li>
                        ))}
                    </ul>
                </div>
            </section>



            <SectionWorld componentDetail={(homeData ?? [])?.find(r => r.component === 'world') ?? false} />

            <YtVideoPopup
                isOpen={isOpen}
                setIsOpen={setIsOpen}
                videoLink={'https://www.youtube.com/embed/46gG8owMTDc'}
            />

        </Layout>
    );
}






export default AboutUs;
