import React from "react";
import logo from "../../resource/image/logo.svg";

const Loading = () => (
    <div className="min-h-screen bg-white  grid place-content-center">
        <img src={logo} alt={'logo'} className={' m-auto w-1/4'}/>
    </div>
);

export default Loading;
