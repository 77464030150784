import { Fragment } from 'react'
import { Popover, Transition } from '@headlessui/react'
import { ChevronDownIcon } from '@heroicons/react/20/solid'
import { HashLink } from 'react-router-hash-link';

const callsToAction = []

export default function SubMenu({label, menuDetails = [], isSimple = false}) {
    return (
        <Popover className="relative">
            <Popover.Button className="inline-flex items-center gap-x-1  leading-6">
                <span>{label}</span>
                <ChevronDownIcon className="h-5 w-5" aria-hidden="true" />
            </Popover.Button>

            <Transition
                as={Fragment}
                enter="transition ease-out duration-200"
                enterFrom="opacity-0 translate-y-1"
                enterTo="opacity-100 translate-y-0"
                leave="transition ease-in duration-150"
                leaveFrom="opacity-100 translate-y-0"
                leaveTo="opacity-0 translate-y-1"
            >
                <Popover.Panel className={isSimple ? "absolute left-1/2 z-10 mt-5 flex w-screen max-w-[15rem] -translate-x-1/2 px-4" : "absolute left-1/2 z-10 mt-5 flex w-screen max-w-max -translate-x-1/2 px-4"}>
                    <div className="w-screen max-w-md flex-auto overflow-hidden rounded-md bg-white text-sm leading-6 shadow-lg ring-1 ring-gray-900/5">
                        <div className="">
                            {menuDetails.map((data) => (
                                <div key={data.id} className={isSimple ? "group relative flex gap-x-3  px-4 py-2 hover:bg-gray-100 items-center" : "group relative flex gap-x-3  p-4 hover:bg-gray-100 items-center" }>
                                    {
                                        data.icon[0] && (
                                            <div className=" flex  flex-none items-center justify-center rounded-lg  ">
                                                <img src={data.icon[0].filename} className="h-11 w-11" alt={data.icon[0].alt} />
                                            </div>
                                        )
                                    }

                                    <div className={""}>
                                        <HashLink to={data.link.url} className="font-medium text-sm tracking-wide text-gray-950">
                                            {data.label}
                                            <span className="absolute inset-0" />
                                        </HashLink>
                                        <p className=" text-gray-600 text-xs tracking-wide">{data?.description ?? '' }</p>
                                    </div>
                                </div>
                            ))}
                        </div>

                        {
                            callsToAction.length > 0 && (
                                <div className="grid grid-cols-2 divide-x divide-gray-900/5 bg-gray-50">
                                    {callsToAction.map((item) => (
                                        <a
                                            key={item.name}
                                            href={item.href}
                                            className="flex items-center justify-center gap-x-2.5 p-3 font-semibold text-gray-900 hover:bg-gray-100"
                                        >
                                            <item.icon className="h-5 w-5 flex-none text-gray-400" aria-hidden="true" />
                                            {item.name}
                                        </a>
                                    ))}
                                </div>
                            )
                        }


                    </div>
                </Popover.Panel>
            </Transition>
        </Popover>
    )
}
