import { useInView } from "framer-motion"
import { useEffect, useRef } from "react"
import { useFeatureStore } from "./store"

function classNames(...classes) {
    return classes.filter(Boolean).join(" ")
}

export const FeatureTitle = ({ children, id }) => {
    const ref = useRef(null)
    const documentRef = useRef(document)
    const isInView = useInView(ref, {
        margin: "-50% 0px -50% 0px",
        root: documentRef
    })
    const setInViewFeature = useFeatureStore(state => state.setInViewFeature)
    const inViewFeature = useFeatureStore(state => state.inViewFeature)

    useEffect(() => {
        if (isInView) setInViewFeature(id)
        if (!isInView && inViewFeature === id) setInViewFeature(null)
    }, [isInView, id, setInViewFeature, inViewFeature])

    return (
        <div
            ref={ref}
            style={{opacity: isInView ? 1 : 0.4}}
            className={classNames(
                "feature-title py-16 font-heading text-5xl transition-colors"
            )}
        >
            {children}
        </div>
    )
}
