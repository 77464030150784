import React, {useEffect, useState} from "react";
import { Route, Routes } from "react-router";
import Homepage from "./pages/homepage";
import {useTranslation} from "react-i18next";
import StoryblokClient from 'storyblok-js-client';
import Loading from "../components/common/loading";
import PlatformPage from "./pages/platform-pages";
import BusinessTypePage from "./pages/business-type-pages";
import AboutUs from "./pages/about-us";
import ContactUs from "./pages/contact-us";
import GenericPage from "./pages/generic-page";
import TryDokless from "./pages/try-dokless";
import HowItWork from "./pages/how-it-work";


function Main() {


    const storyBlok = new StoryblokClient({
        accessToken: 'bEHXdbmh4yqlKx0Fc9QHfwtt',
    });
    const { i18n } = useTranslation();
    let language = i18n.language.includes('en') ? 'en' : 'it';


    const [siteConfig, setSiteConfig] = useState(false);
    const [isLoading, setLoading] = useState(true);

    useEffect(() => {
        setLoading(true);
        setSiteConfig(false);
    }, [language]);

    useEffect(() => {
        if(!siteConfig){
            storyBlok.get(`cdn/stories/config`, {"language": language})
                .then(response => {
                    setSiteConfig(response.data.story);
                    setLoading(false);
                }).catch(error => {
                console.error('Error fetching story:', error);
            });
        }
        // eslint-disable-next-line
    }, [storyBlok]);


    if(isLoading){
        return (<><Loading /></>);
    }


    return (
        <Routes>
            <Route path="/" element={<Homepage siteConfig={siteConfig} storyBlok={storyBlok} language={language} />} />
            <Route path="/pages/platform/:page" element={<PlatformPage siteConfig={siteConfig} storyBlok={storyBlok} language={language} />} />
            <Route path="/pages/business-type/:page" element={<BusinessTypePage siteConfig={siteConfig} storyBlok={storyBlok} language={language} />} />
            <Route path="/pages/company/about-us" element={<AboutUs siteConfig={siteConfig} storyBlok={storyBlok} language={language} />} />
            <Route path="/pages/company/contact-us" element={<ContactUs siteConfig={siteConfig} storyBlok={storyBlok}  language={language} />} />
            <Route path="/pages/generic/:page" element={<GenericPage siteConfig={siteConfig} storyBlok={storyBlok} />} language={language}  />
            <Route path="/try-dokless" element={<TryDokless siteConfig={siteConfig} storyBlok={storyBlok} />} language={language} />
            <Route path="/how-it-work" element={<HowItWork siteConfig={siteConfig} storyBlok={storyBlok} />}  language={language} />
        </Routes>
  );
}

export default Main;
