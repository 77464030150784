import {  Fragment, useState } from 'react'
import {NavLink, Link} from "react-router-dom";
import { Popover, Transition } from '@headlessui/react'
import { AnimatePresence, motion } from 'framer-motion'
import { NavLinks } from '../common/nav-links'
import SubMenu from "./sub-menu";
import {useTranslation} from "react-i18next";
import itFlag from "../../resource/image/it.png";
import enFlag from "../../resource/image/en.png";
import {supportedLocalesSelect} from "../../i18n";
import {Button} from "../common/button";
import {TRY_DOKLESS} from "../../constants/urls";

function classNames(...classes) {
    return classes.filter(Boolean).join(" ")
}


function MenuIcon(props) {
    return (
        <svg viewBox="0 0 24 24" fill="none" aria-hidden="true" {...props}>
            <path
                d="M5 6h14M5 18h14M5 12h14"
                strokeWidth={2}
                strokeLinecap="round"
                strokeLinejoin="round"
            />
        </svg>
    )
}

function ChevronUpIcon(props) {
    return (
        <svg viewBox="0 0 24 24" fill="none" aria-hidden="true" {...props}>
            <path
                d="M17 14l-5-5-5 5"
                strokeWidth={2}
                strokeLinecap="round"
                strokeLinejoin="round"
            />
        </svg>
    )
}

function MobileNavLink({ children, ...props }) {
    return (
        <NavLink
            to={props.href}
            className="block text-sm leading-7 tracking-wide text-gray-700"
            {...props}
        >
            {children}
        </NavLink>
    )
}

export function Navigation({siteConfig, isFixed = false, isDark = false, isBlur = false }) {


    const { t } = useTranslation();
    const [isActiveMobileMenu, setActiveMobileMenu] = useState(false);


    return (
        <header className={classNames(
                isDark ?  "" : "",
                        isFixed  ? 'border-b border-gray-200 bg-white' : "sticky top-0 z-40 w-full flex-none duration-500 lg:z-50",
                        isBlur ? 'duration-500  backdrop-blur transition-colors duration-500  lg:border-slate-900/10 dark:border-slate-50/[0.06] bg-slate-900 supports-backdrop-blur:bg-slate-900/95 bg-slate-900/75' : ""
        )}

        >
            <nav>
                <div className="mx-auto max-w-7xl py-4 px-4 sm:px-6 lg:px-8 relative z-50 flex justify-between ">
                    <div className="relative z-10 flex items-center gap-16">
                        <NavLink to="/" >
                            <img src={(isDark && !isActiveMobileMenu) ? siteConfig?.content?.header_logo_white?.filename : siteConfig?.content?.header_logo?.filename}  className="h-10 w-auto" alt="" />
                        </NavLink>
                        <div className="hidden lg:flex lg:gap-10">
                            <NavLinks siteConfig={siteConfig} isDark={isDark} />
                        </div>
                    </div>
                    <div className="flex items-center gap-6">
                        <LanguageSwitch />

                        <Popover className="lg:hidden items-center">
                            {({ open }) => {
                                setActiveMobileMenu(open);
                                return (
                                    <>
                                        <Popover.Button
                                            className={classNames(
                                                "relative z-10 -m-2 inline-flex items-center rounded-lg  p-2 ui-not-focus-visible:outline-none",
                                                (isDark && !isActiveMobileMenu) ?  "stroke-white hover:bg-gray-200/50 hover:stroke-white active:stroke-gray-900 " : "stroke-gray-900 hover:bg-gray-200/50 hover:stroke-gray-600 active:stroke-gray-900 ",
                                            )}
                                            aria-label="Toggle site navigation"
                                        >
                                            {({ open }) =>
                                                open ? (
                                                    <ChevronUpIcon className="h-6 w-6" />
                                                ) : (
                                                    <MenuIcon className="h-6 w-6" />
                                                )
                                            }
                                        </Popover.Button>
                                        <AnimatePresence initial={false}>
                                            {open && (
                                                <>
                                                    <Popover.Overlay
                                                        static
                                                        as={motion.div}
                                                        initial={{ opacity: 0 }}
                                                        animate={{ opacity: 1 }}
                                                        exit={{ opacity: 0 }}
                                                        className="fixed inset-0 z-0 bg-gray-300/60 backdrop-blur"
                                                    />
                                                    <Popover.Panel
                                                        static
                                                        as={motion.div}
                                                        initial={{ opacity: 0, y: -32 }}
                                                        animate={{ opacity: 1, y: 0 }}
                                                        exit={{
                                                            opacity: 0,
                                                            y: -32,
                                                            transition: { duration: 0.2 },
                                                        }}
                                                        className="absolute inset-x-0 top-0 z-0 origin-top rounded-b-2xl bg-gray-50 px-6 pb-6 pt-32 shadow-2xl shadow-gray-900/20"
                                                    >
                                                        <div className="space-y-4">
                                                            {
                                                                (siteConfig?.content?.header_nav ?? []).map((data, index) => (
                                                                    <>
                                                                        {
                                                                            data.type === 'normal' && (
                                                                                <MobileNavLink key={data.id} href={data.link.url}>
                                                                                    {data.label}
                                                                                </MobileNavLink>
                                                                            )
                                                                        }

                                                                        {
                                                                            data.type === 'submenu' && (
                                                                                <div key={data.id} className="block text-base leading-7 tracking-tight text-gray-700">
                                                                                    <SubMenu label={data.label}  menuDetails={data.submenu} />
                                                                                </div>
                                                                            )
                                                                        }

                                                                        {
                                                                            data.type === 'simple-submenu' && (
                                                                                <div key={data.id} className="block text-base leading-7 tracking-tight text-gray-700">
                                                                                    <SubMenu label={data.label} isSimple={true} menuDetails={data.submenu} />
                                                                                </div>
                                                                            )
                                                                        }
                                                                    </>
                                                                ))
                                                            }
                                                        </div>


                                                        <div className="mt-8 flex flex-col gap-4">
                                                            <a href="https://app.dokless.com/login" className="inline-flex justify-center rounded-lg border py-[calc(theme(spacing.2)-1px)] px-[calc(theme(spacing.3)-1px)] text-sm outline-2 outline-offset-2 transition-colors border-gray-300 text-gray-700 hover:border-gray-400 active:bg-gray-100 active:text-gray-700/80">
                                                                {t("menu.login")}
                                                            </a>
                                                            <Link to={TRY_DOKLESS}
                                                               className="inline-flex justify-center rounded-lg border py-[calc(theme(spacing.2)-1px)] px-[calc(theme(spacing.3)-1px)] text-sm outline-2 outline-offset-2 transition-colors bg-indigo-950 text-white hover:bg-indigo-800 active:bg-indigo-800 active:text-white/80">
                                                                {t("menu.try_dokless")}
                                                            </Link>
                                                        </div>
                                                    </Popover.Panel>
                                                </>
                                            )}
                                        </AnimatePresence>
                                    </>
                                )

                            }}
                        </Popover>


                        <a href="https://app.dokless.com/login"
                           className={classNames(
                               "inline-flex text-sm justify-center hidden lg:block tracking-wide",
                               isDark ?  "text-white/80 transition-colors  hover:text-white hover:delay-[0ms]" :
                                   "text-blue-1000/80 transition-colors delay-150 hover:text-blue-1000 hover:delay-[0ms] ",
                           )}
                        >
                            {t("menu.login")}
                        </a>

                        <Button href={TRY_DOKLESS} variant={'outline'} color={isDark ?  'white' : 'indigo'} className={'hidden lg:block'}>
                            <span className="">{t("menu.try_dokless")}</span>
                        </Button>

                    </div>
                </div>
            </nav>
        </header>
    )
}

const LanguageSwitch = () => {

    const { i18n } = useTranslation();
    const setLanguage = (e) => {
        const code = e.target.dataset.locale;
        i18n.changeLanguage(code);
    };


    return (
        <Popover className="inline-flex relative -mt-2 md:mt-0">
            <Popover.Button className="inline-flex items-center gap-x-1 text-sm font-semibold leading-6 lg:text-white text-grey-900 border-0 outline-0">
                <img alt='' src={i18n.language.includes('en') ? enFlag : itFlag} className={'w-5 h-5'} />
            </Popover.Button>

            <Transition
                as={Fragment}
                enter="transition ease-out duration-200"
                enterFrom="opacity-0 translate-y-1"
                enterTo="opacity-100 translate-y-0"
                leave="transition ease-in duration-150"
                leaveFrom="opacity-100 translate-y-0"
                leaveTo="opacity-0 translate-y-1"
            >
                <Popover.Panel className="absolute  z-10 mt-8 flex w-screen -translate-x-10">
                    <div className=" shrink rounded-sm py-2 px-4  text-sm font-semibold leading-6 bg-white text-gray-900">
                        {Object.entries(supportedLocalesSelect).map(
                            ([code, { nativeName }]) => (
                                (!i18n.language.includes(code)) && (
                                    <div className="flex items-center cursor-pointer" key={code} onClick={setLanguage}>
                                        <img src={code === 'en' ? enFlag : itFlag} className={'w-5 h-5 mr-2'} alt={nativeName} />
                                        <div
                                            data-locale={code}
                                            className={"block hover:text-indigo-600  text-grey-900"}
                                        >
                                            {nativeName}
                                        </div>
                                    </div>
                                )
                            )
                        )}


                    </div>





                </Popover.Panel>
            </Transition>
        </Popover>
    )
}
