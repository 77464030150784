import {useEffect, useState} from "react";
import {useNavigate, useParams} from "react-router-dom";
import Loading from "../../components/common/loading";
import {useForm } from "react-hook-form";
import InputText from "../../components/form/input-text";
import {useTranslation} from "react-i18next";
import InputBaseSelect from "../../components/form/input-base-select";
import InputSubmit from "../../components/form/input-submit";
import axios from 'axios';
import {Button} from "../../components/common/button";
import {TRY_DOKLESS} from "../../constants/urls";
import VideoPopup from "../../components/common/video-popup";
import {emailRegex} from "../../constants/regex";


function HowItWork({siteConfig, storyBlok, language}) {

    const [pageDetail, setPageDetail] = useState(false);
    const [isLoadingPage, setLoadingPage] = useState(true);
    const [isSendLoading, setSendLoading] = useState(false);
    const { t } = useTranslation();
    const [isOpenVideo, setOpenVideo] = useState(false);
    const [exception, setException] = useState(false);


    const {
        register,
        handleSubmit,
        formState: { errors }
    } = useForm();

    let navigate = useNavigate();

    let {page}  = useParams();
    const { i18n } = useTranslation();


    useEffect(() => {
        setLoadingPage(true);
        storyBlok.get(`cdn/stories/pages/form/how-it-work`, {"language": language})
            .then(response => {
                setPageDetail(response.data.story.content.body);
                setLoadingPage(false);
            }).catch(error => {
            navigate("/")
        });

    }, [page, storyBlok, navigate, language]);


    if(isLoadingPage){
        return (<><Loading /></>);
    }

    const pageData = (pageDetail ?? [])?.find(r => r.component === 'page') ?? false


    const onSubmit = async (data) => {


        data['locale'] = i18n.language.includes('en') ? 'en' : 'it';
        setException(false);
        setSendLoading(true);
        axios.post('https://api.dokless.com/api/request_demo', data)
            .then(function (response) {
                setOpenVideo(true);
        }).catch(function (error) {
            setException(true);

        }).finally(function () {
                setSendLoading(false);
        });

    };

    return (

        <>
            <div className="relative flex h-screen shrink-0 justify-center md:px-12 lg:px-0 lg:overflow-y-hidden bg-white">
                <div className=" relative z-10 flex flex-1 flex-col bg-white px-4 py-10 xl:shadow-2xl  md:justify-center md:flex-none md:px-16">
                    <main className="lg:overflow-y-scroll mx-auto w-full max-w-xl sm:px-4   md:px-8 ">
                        <a href="/" className={' '}>
                            <img src={siteConfig?.content?.header_logo?.filename}  className="h-10 mx-0 md:mx-auto w-auto" alt="" />
                        </a>
                        <h2 className="mt-6 text-3xl font-extrabold text-gray-900 text-center">
                            {pageData.title}
                        </h2>
                        <p className="mt-2 text-base text-gray-700 text-center" dangerouslySetInnerHTML={{__html:  storyBlok.richTextResolver.render(pageData.description ?? [])}} />

                        <form onSubmit={handleSubmit(onSubmit)} className="mt-10 grid grid-cols-1 gap-y-8">

                            <InputText
                                id={"first_name"}
                                type={"text"}
                                errors={errors.first_name}
                                input={{ ...register("first_name", { required: true }) }}
                                label={t("app.form.firstname")}
                            />

                            <InputText
                                id={"last_name"}
                                type={"text"}
                                errors={errors.last_name}
                                input={{ ...register("last_name", { required: true }) }}
                                label={t("app.form.lastname")}
                            />

                            <InputText
                                id={"email"}
                                type={"text"}
                                errors={errors.email}
                                input={{ ...register("email", { required: true, pattern: emailRegex }) }}
                                label={t("app.form.company_mail")}
                            />

                            <InputText
                                id={"company_name"}
                                type={"text"}
                                errors={errors.company_name}
                                input={{ ...register("company_name", { required: true }) }}
                                label={t("app.form.company_name")}
                            />


                            <InputText
                                id={"phone_number"}
                                type={"text"}
                                errors={errors.phone_number}
                                input={{ ...register("phone_number", { required: true }) }}
                                label={t("app.form.phone")}
                            />
                            <InputBaseSelect
                                id={'source'}
                                options={[{name: "LinkedIn", value: "LinkedIn"},{name: "Google", value: "Google"},{name: "Instagram", value: "Instagram"},{name: "Email", value: "Email"},{name: t("app.form.friend"), value: 'Friend'},{name: t("app.form.other"), value: 'other'}]}
                                errors={errors.source}
                                input={{...register("source", { required: true })}}
                                label={t("app.form.hear_where")}
                            />


                            {
                                exception && (
                                    <div className="mt-5">
                                        <div className={`bg-red-50 border-l-4 border-red-400 p-4`}>
                                            <div className="flex">
                                                <div className="ml-3">
                                                    <p className={`text-sm text-red-700`}>
                                                        {t("app.form.error")}
                                                    </p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                )
                            }



                            <InputSubmit
                                isLoading={isSendLoading}
                                isFullWith={true}
                                label={t("app.form.btn_confirm")}
                            />
                        </form>
                    </main>
                </div>
                <div className=" hidden  xl:relative xl:block xl:flex-1 bg-black bg-gradient-to-b from-indigo-950/90">
                    <img
                        className="absolute inset-0 h-full w-full object-contain px-16"
                        src={pageData.background}
                        alt=""
                    />
                </div>

                <div className={'absolute z-50 top-10 bottom-auto right-[5%]'}>
                    <Button className={'font-extrabold'} href={TRY_DOKLESS}>
                        {t("menu.try_dokless")}
                    </Button>
                </div>
            </div>

            { isOpenVideo && (
                <VideoPopup
                    setIsOpen={setOpenVideo}
                    isOpen={isOpenVideo}
                    videoLink={pageData.video_link}
                />
            )

            }
        </>
    );
}






export default HowItWork;
