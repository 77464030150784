import Header from "../../components/layout/header";
import PlatformFeatures from "../../components/common/platform-feature";
import FeatureBuild from "../../components/common/feature-build";
import FeatureManage from "../../components/common/feature-manage";
import SectionWorld from "../../components/common/section-world";
import React, { useState, useEffect } from 'react';
import communicationFeatureBg from "../../resource/image/base-feature/communication.svg";
import operabilityBg from "../../resource/image/base-feature/operability.svg";
import Loading from "../../components/common/loading";
import Layout from "../../components/layout/layout";
import CurvedDiv from "../../components/common/curved-div";
import FeatureBuildDark from "../../components/common/feature-build-dark";
import Testimonials from "../../components/common/testimonials";
import Cookie from "../../components/common/cookie";

const Homepage = ({siteConfig, storyBlok, language}) => {

    const [pageDetail, setPageDetail] = useState([]);
    const [isLoading, setLoading] = useState(true);

    useEffect(() => {
        storyBlok.get(`cdn/stories/homepage`, {"language": language})
            .then(response => {
                setPageDetail(response.data.story.content.body);
                setLoading(false);
            }).catch(error => {
            console.error('Error fetching story:', error);
        });
    }, [storyBlok, language]);


    if(isLoading){
        return (<><Loading /></>);
    }

    return (

        <Layout siteConfig={siteConfig}>


            <Header siteConfig={siteConfig}  componentDetail={(pageDetail ?? [])?.find(r => r.component === 'banner') ?? false}  />
            <CurvedDiv color={"#000000"} className={'rotate-180 -mb-10  -mt-1 '} />
            <PlatformFeatures componentDetail={(pageDetail ?? [])?.find(r => r.component === 'grid-section') ?? false} />
            <FeatureBuildDark bg={communicationFeatureBg} color={'bg-indigo-950'} componentDetail={(pageDetail ?? [])?.find(r => r.component === 'block-feature-1') ?? false} />
            <FeatureManage componentDetail={(pageDetail ?? [])?.find(r => r.component === 'block_feature_2') ?? false} />


            <CurvedDiv color={"#c7d2fe"} className={'rotate-180 -z-10 -mb-10 '} />
            <FeatureBuild bg={operabilityBg} color={' bg-solitude-100'} componentDetail={(pageDetail ?? [])?.find(r => r.component === 'block_feature_3') ?? false} />
            <Testimonials componentDetail={(pageDetail ?? [])?.find(r => r.component === 'testimonial') ?? false} />

            <SectionWorld componentDetail={(pageDetail ?? [])?.find(r => r.component === 'world') ?? false} />

            <Cookie />

        </Layout>

  );
}


export default Homepage;
